import React, { Component } from 'react'
import {  Form } from "react-bootstrap";
import { Link } from 'react-router-dom'
import axios from 'axios'
import Button from 'react-bootstrap/Button'




export default class OutputConnectionTableRow extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.setState({
      cGUID: this.props.obj.ConnectorGUID,
      TransmissionType: this.props.obj.TransmissionType ,
      TransmissionNote: this.props.obj.TransmissionNote ,
      PushPull: this.props.obj.PushPull ,
    })
  }

  render() {
    return (
<div>
      <p>

      {this.props.obj.PushPull} TO:  ----

      <a href={  '/edit-assertion/' + this.props.obj.targetAutoID}>  
        <b><font color="navy">
      {this.props.obj.TargetSpecID}  -
      {this.props.obj.TargetSpecName}
      </font></b>
        </a>
        
      </p>

    

</div>
    )
  }
}
