import React, { Component } from 'react'



export default class EnvironmentOptionsRow extends Component {
  constructor(props) {
    super(props)
  }

  render() {
      
    return <option value={this.props.obj.id }>{this.props.obj.Name  + "       [  " +   this.props.obj.Description + ']'}</option>

  }
}
