import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import axios from 'axios'

import strBEURL from './backend-url';

//let strBEURL = 'https://clammytest.assertions.ca'   // back end url for prod  BE
// strBEURL = 'http://localhost:3030'
const showFeedback = ((feedback,place) => {
    if (document.getElementById(place) ){
      document.getElementById(place).innerHTML = feedback
    }
  })

export default class AttributeTableRow extends Component {
    constructor(props) {
        super(props)
        this.state={
            ModelID: '',
            strParentGUID: '',
            Name: '',
            sequence: '',
            Description: '',
            ModelNote: '',
            blnCollection: '',
            blnPrimitive: '',
            ComplexTypeName: '',
            strComplexTypeGUID: '',
            intDisplayRows: '',
            PrototypeValue: '',
            strAttributeGUID: '',
        }
    }
 // ================== COMPONENT MOUNT ====================================================================
    componentDidMount() {  
        this.setState({
            ModelID: this.props.attribute.MODELID,
            strParentGUID: this.props.attribute.TYPEGUID,
            sequence: this.props.attribute.TYPESEQ,
            strAttributeGUID: this.props.attribute.ATTRGUID,
            Name: this.props.attribute.ATTRNAME,
            Description: this.props.attribute.ATTRDESC,
            ModelNote: this.props.attribute.ATTRMONOTE,
            blnCollection : this.props.attribute.ATTRCOLLN,
            blnPrimitive: this.props.attribute.ATTRPRIM == null?true   :   this.props.attribute.ATTRPRIM ,
            PrimitiveType: this.props.attribute.ATTRPRIMTYPE,
            ComplexTypeName: this.props.attribute.COMPLEXNAME,
            strComplexTypeGUID: this.props.attribute.COMPLEXGUID,
            intDisplayRows: this.props.attribute.ATTRROWS,
            PrototypeValue: this.props.attribute.ATTRPROTYPE,
           


            
            originalBlnIsPrimitive: this.props.attribute.ATTRPRIM == null?true   :   this.props.attribute.ATTRPRIM  , // keep this to know if change on update button  // ==null also catches undefined
        })

 // ================== ONCHANGE BINDINGS ====================================================================
        this.updateAttribute = this.updateAttribute.bind(this)
        this.deleteAttribute = this.deleteAttribute.bind(this)
        this.addAttribute = this.addAttribute.bind(this)

        this.onChangeATTRNAME = this.onChangeATTRNAME.bind(this)  
        this.onChangeATTRDESC = this.onChangeATTRDESC.bind(this) 
        this.onChangeATTRCOLN = this.onChangeATTRCOLN.bind(this) 
        this.onChangeATTRPRIM = this.onChangeATTRPRIM.bind(this) 
        this.onChangeATTRPRIMTYPE = this.onChangeATTRPRIMTYPE.bind(this) 
        this.onChangeCOMPLEXNAME = this.onChangeCOMPLEXNAME.bind(this) 
        this.onChangeATTRROWS = this.onChangeATTRROWS.bind(this) 
        this.onChangeATTEPROTOTYPE = this.onChangeATTEPROTOTYPE.bind(this) 

    }
 // ================== ONCHANGE FUNCTIONS ====================================================================
//  each of below are updated in the state as the attribute is edited.  -- but only updated in endpoint on UPDATE BUTTON
//    MUST BE MANAGED INTERNALLY SOMEHOW   --  strParentGUID    // just stored in state but not changed
    onChangeATTRNAME(e) {this.setState({Name: e.target.value })}  // keep the state in line with screen values
    onChangeATTRDESC(e) {this.setState({Description: e.target.value })}  // keep the state in line with screen values
    onChangeATTRCOLN(e) {this.setState({blnCollection: e.target.checked}) }   // works
    onChangeATTRPRIMTYPE(e) {this.setState({PrimitiveType: e.target.value }) }  
    onChangeATTRPRIM(e) {

        // for this field the state is not changed.  But a warning is given that a type may be added or deleted!
        // when the updateBE occurs the check occurs to deterine if change ste is necessary.

        this.setState({blnPrimitive: e.target.checked}) 
        // it is changed 
        if (e.target.checked) { //  if checked it was switched to primitive  - must confirm removal of type and if not switch it back
          // call axios delete type - it should rerender
            if (document.getElementById("messagePlaceType") ){
                document.getElementById("messagePlaceType").innerHTML =  'WARNING:  You have chosen to make: ' + this.state.Name + ' primitive. When you update this Attribute its Type may dissappear!'
            }
        } else{
            if (document.getElementById("messagePlaceType") ){
                    document.getElementById("messagePlaceType").innerHTML =  'WARNING:  You have chosen to make: ' + this.state.Name + ' a complex type . When you update this Attribute a new  Type may appear!'
            }
        }   
    }   
   
    onChangeCOMPLEXNAME(e) {this.setState({ComplexTypeName: e.target.value })}  // keep the state in line with screen values
    //   MUST BE MANAGED INTERNALLY SOMEHOW   -- strComplexTypeGUID: this.props.attribute.COMPLEXGUID,
    onChangeATTRROWS(e) {this.setState({intDisplayRows: e.target.value })}  // keep the state in line with screen values 
    onChangeATTEPROTOTYPE(e) {this.setState({PrototypeValue: e.target.value })}  // keep the state in line with screen values

 // ================== UTILITY FUNCTIONS ====================================================================

    logMessage(mess) {   //  show on the page
        if (document.getElementById("messagePlaceType") ){
            document.getElementById("messagePlaceType").innerHTML =  mess
            }
    }
    

 // ================== DELETE ATTRIBUTE ====================================================================
    deleteAttribute(){
      let url =  strBEURL + '/attribute/delete-attribute/?strAttributeGUID=' + this.state.strAttributeGUID
       console.log('In Delete Attribute. sending : ' + url)
        axios.get(url)     //  update is by strAttributeGUID
            .then((res) => {
                this.logMessage(' Deleted Attribute OK: ' + this.state.Name)
            })
            .catch(function (error) {
                this.logMessage(' Failed Delete.  ERROR: ' + error)

            })
            // now invoke parent refresh
            this.props.handleDeleteAttr()
    }

 // ==================ADD ATTRIBUTE====================================================================
    addAttribute(){
        console.log('In Add Attribute.   this.state.strParentGUID: ' + this.state.strParentGUID)
      
        //axios.get( strBEURL + '/attribute/update-attribute/?strTypeGUID=' + this.state.strParentGUID)     //  update is by strAttributeGUID
        axios.get( strBEURL + '/attribute/add-attribute/?strTypeGUID=' + this.state.strParentGUID)     //  update is by strAttributeGUID
            .then((res) => {
                this.logMessage(' ADD OK: ' + this.state.Name)
            })
            .catch(function (error) {
                this.logMessage(' ADD ERROR: ' + error)
            })
    }
 // ================== UPDATE ATTRIBUTE====================================================================

 // Lets rewrite update for single type
 updateAttribute(){
    // create the body and use post
    // I think the state is the body??
    
    let attrUpdated = {...this.state}
    delete attrUpdated.originalBlnIsPrimitive

    console.log('this.state is: \n'+ JSON.stringify( attrUpdated))
    axios.post(
        strBEURL + '/attribute/post-update-attribute/'  ,
         attrUpdated
    )
    .then(reply => {
        showFeedback(  reply.data.message ,"messagePlaceType")
    })
    .catch((error) => {
        console.log(error)
        showFeedback(  "Failed to update with error: " + error ,"messagePlaceType")
      }) 

 }
/* GO 
    updateAttributeDEPREC(){
// This performs a simple update for the Attribute but
// BUT if it has change isPrimitive it will add or delete subtype as needed.   -- No 0- subTypes were abandonned
        const uudiv4 = ()=>{
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0, 
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
console.log(this.state.PrototypeValue)
console.log(this.state.PrototypeValue.replace(/\r?\n/g, "\\n\\"))
       

         const strParameters =  ()=>{
            let strUnencoded =  "?strParentGUID=" + this.state.strParentGUID  
            + "&sequence=" + this.state.sequence  
            + "&Name=" + this.state.Name
            + "&Description=" + this.state.Description 
            + "&ModelNote=" + this.state.ModelNote 
            + "&blnCollection=" + this.state.blnCollection    // true
            + "&blnPrimitive=" + this.state.blnPrimitive     // true
            + "&PrimitiveType=" + this.state.PrimitiveType 
            + "&ComplexTypeName=" + this.state.ComplexTypeName 
            + "&strComplexTypeGUID=" + this.state.strComplexTypeGUID  //  needed if complex
            + "&intDisplayRows=" + this.state.intDisplayRows 
            + "&PrototypeValue=" + this.state.PrototypeValue.replace(/\r?\n/g, "\\" + "n")  // this keeps the linefeed
            + "&strAttributeGUID=" + this.state.strAttributeGUID ;

            //return encodeURIComponent(strUnencoded)   // does not seem to work  this definitly causes a 404
            //let better = strUnencoded.replace(/\\n/g,"\n");  // purportedly replaces the line feed character with the two characters / and n
            //let better = strUnencoded.replace(/\r?\n/g, "\n");  // hacking here
            return strUnencoded 
        }

        let reply = 'Reply: '
        // first case
        let blnComplexChanged = !(this.state.originalBlnIsPrimitive === this.state.blnPrimitive) 
        if (blnComplexChanged  && this.state.blnPrimitive) {    // change to primitive  - so update attr and DELETE Type
            axios.post( strBEURL + '/type/delete-type/?strTypeGUID='  + this.state.strParentGUID)
            .then( deleteResult => {reply = reply + deleteResult.message   })     //  at this point the update is done and the delete type is done -- so send result    >>>>>>>>>>>>>>>>>>>>>>>>>>
            .then( rep => {return axios.post('https://' +  strBEURL + '/attribute/post-update-attribute/'  + strParameters())  })
            .then( upRes =>{this.logMessage(reply + upRes.message)})
            .catch( (error) =>{this.logMessage(' UPDATE ERROR: ' + error)})
        } 
        // second case  to COMPLEX
        if (blnComplexChanged  && !this.state.blnPrimitive) {    // change to complex - so update attr  and CREATE type
            let thisGUID = uudiv4()
            //strParam.strComplexTypeGUID = thisGUID   // this does not change the string  set state
            this.setState({strComplexTypeGUID: thisGUID })
            let strParams3=strParameters()
            axios.get( strBEURL + '/attribute/update-attribute/'  + strParams3)
            .then(upRes2 =>{
                reply = reply + upRes2.data.message
                let strParam2 ="?strTypeGUID=" + thisGUID 
                    + "&complexName=T_" + this.state.Name 
                    + "&strParentGUID="  + this.state.strParentGUID
                return axios.get( strBEURL + '/type/add-complex-type/'  + strParam2)    
            })
            .then(addTypRes =>{this.logMessage(reply + 'xx' + addTypRes.data.message)})
            .catch( (error) =>{this.logMessage(' UPDATE ERROR: ' + error)})
        }
        // third case
        if (this.state.originalBlnIsPrimitive ===  this.state.blnPrimitive ) {    // no change - just update  - one is string
            let strParams4=strParameters()
            axios.get( strBEURL + '/attribute/update-attribute/'  + strParams4)
            .then(upRes3 =>{this.logMessage(upRes3.data.message) })
            .catch( (error) =>{this.logMessage(' UPDATE ERROR: ' + error)})
        }
    }
GO */
 // ================== RENDER ====================================================================
    render() {
        return (  
            <React.Fragment>
                <tr>
                    <td width='25%'>
                        <Form.Group controlId="ATTRNAME_"  >
                            <Form.Control  className="rl-attr-name" type="text" defaultValue={this.state.Name}  onChange={this.onChangeATTRNAME}/>
                        </Form.Group>
                    </td>
                    <td width='30%'>
                        <Form.Group controlId="ATTRDESC_"  >
                            <Form.Control type="text" as="textarea" rows={1} defaultValue={this.state.Description}  onChange={this.onChangeATTRDESC}/>
                        </Form.Group>
                    </td>
                    <td width='2%'>
                        <Form.Check
                            inline
                            label=""
                            name="ATTRCOLN"
                            type="checkbox"
                            id={`inline-radio`}
                            checked={this.state.blnCollection === true}
                            onChange={this.onChangeATTRCOLN} 
                        />
                    </td>
                    <td width='2%'>
                        <Form.Check
                            inline
                            label=""
                            name="ATTRPRIM"
                            type="checkbox"
                            id={`inline-radio`}
                            checked={this.state.blnPrimitive === true}
                            onChange={this.onChangeATTRPRIM} 
                        />
                    </td>
                    <td width='10%'>
                        <Form.Select
                        as="select"
                        value={this.state.PrimitiveType} 
                        onChange={this.onChangeATTRPRIMTYPE}
                        custom
                        >
                            <option value="STRING">STRING</option>
                            <option value="BOOLEAN">BOOLEAN</option>
                            <option value="INTEGER">INTEGER</option>
                            <option value="NUMBER">NUMBER</option>
                            <option value="DATE">DATE</option>
                            <option value="CURRENCY">CURRENCY</option>
                            <option value="IMAGE">IMAGE</option>
                            <option value="HTML">HTML</option>
                            <option value="DOCUMENT">DOCUMENT</option>
                        </Form.Select>
                    </td>
                    <td width='7%'>
                    <Form.Group controlId="COMPLEXNAME} _"  >
                        <Form.Control type="text" defaultValue={this.state.ComplexTypeName}  onChange={this.onChangeCOMPLEXNAME }/>
                    </Form.Group>
                    </td>
                    <td width='3%'>
                    <Form.Group controlId="ATTRROWS} _"  >
                        <Form.Control type="text" defaultValue={this.state.intDisplayRows}  onChange={this.onChangeATTRROWS }/>
                    </Form.Group>
                    </td>
                    <td width='3%'>
                        <Button  onClick={this.updateAttribute} size="sm" variant="warning" >
                            Update
                        </Button>
                    </td>


                    <td width='3%'>
                        <Button onClick={this.deleteAttribute} size="sm" variant="danger">
                            Delete
                        </Button>
                    </td>

            
                </tr>
            <React.Fragment>
            </React.Fragment>
                <tr> 
                    <td  colspan='7'width='100%'>
                    <Form.Group controlId="ATTPPROTOTYPE} _"  >
                        <Form.Control type="text" 
                        className="rl-attr-proto" as="textarea" rows={1} defaultValue={this.state.PrototypeValue}  onChange={this.onChangeATTEPROTOTYPE }/>
                    </Form.Group>
                    </td>
                </tr>
        </React.Fragment>

        )
    }
}
