import React, { Component } from 'react'
import {  Form } from "react-bootstrap";
import { Link } from 'react-router-dom'
import axios from 'axios'
import Button from 'react-bootstrap/Button'


import strBEURL from './backend-url';

//let strBEURL = 'https://clammytest.assertions.ca'   // back end url for prod  BE
// strBEURL = 'http://localhost:3030'

export default class AuthorityTableRow extends Component {
  constructor(props) {
    super(props)
    this.deleteAuthority = this.deleteAuthority.bind(this)
    this.updateAuthority = this.updateAuthority.bind(this)

    this.onChangeAuthID = this.onChangeAuthID.bind(this)
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  componentDidMount() {
    this.setState({
      ID: this.props.obj.id,
      AuthID: this.props.obj.AuthID ,
      Name: this.props.obj.Name ,
      Description: this.props.obj.Description ,
    })
//  console.log(state)   // state is not defined here

  }

  onChangeAuthID(e) {this.setState({AuthID: e.target.value })}  // thses set value for the state that is subsequenlty passed to BE for update
  onChangeName(e) {this.setState({ Name: e.target.value })}
  onChangeDescription(e) {this.setState({ Description: e.target.value })}


  deleteAuthority() {
    axios
      .get(
         strBEURL + '/model/delete-authority/?id=' + this.props.obj.id,
      )
      .then((res) => {
        if (document.getElementById("messagePlace") ){
          document.getElementById("messagePlace").innerHTML =  ' DELETE : ' + res.data.message
          this.forceUpdate();   // try to get the list to update  -- works  -- it is React call
        }
       
      })
      .catch((error) => {
        if (document.getElementById("messagePlace") ){
          document.getElementById("messagePlace").innerHTML =  ' DELETE ERROR: ' + error
      }
      })
  }

  updateAuthority(){

    let strParam = "&id=" + this.state.ID  +"&Name=" + this.state.Name  + "&AuthID=" + this.state.AuthID    + "&Description=" + this.state.Description 
 

    axios.get( strBEURL + '/model/update-authority/?'  + strParam)
      .then((res) => {

        if (document.getElementById("messagePlace") ){
          document.getElementById("messagePlace").innerHTML =  ' UPDATE OK: ' + res.data
      }

        //document.getElementById("messagePlace").innerHTML = "JDON:res.data is: " + JSON.stringify(res.data);  // should find the element below??
      })
      .catch(function (error) {
        if (document.getElementById("messagePlace") ){
          document.getElementById("messagePlace").innerHTML =  ' UPDATE ERROR: ' + error
      }
        //document.getElementById("messagePlace").innerHTML = "Error: " + error + '\n Possible access control. Ensure chrome allows cross domain. RUN: chrome.exe --user-data-dir="C:/Chrome dev session" --disable-web-security';  // should find the element below??  // this does update when the 
      })

  }

  render() {
   //console.log(JSON.stringify(this.state))
    return (

   
      <tr>
        <td>
          <Form.Group controlId="AuthID_"  >
            <Form.Control type="text" defaultValue={this.props.obj.AuthID } onChange={this.onChangeAuthID}/>
          </Form.Group>
        </td>
        <td>
        <Form.Group controlId="Name_"  >
            <Form.Control className="rl-auth-name" size="lg" type="text" defaultValue={this.props.obj.Name }  onChange={this.onChangeName}/>
          </Form.Group>
        </td>
        <td>
        <Form.Group controlId="Description_"  >
            <Form.Control type="text" defaultValue={this.props.obj.Description}  onChange={this.onChangeDescription}/>
          </Form.Group>
        </td>
        <td>
          <Button onClick={this.deleteAuthority} size="sm" variant="danger">
            Delete
          </Button>
        </td>
        <td>
        <Button  onClick={this.updateAuthority} size="sm" variant="primary" >
          Update
        </Button>
        </td>
      </tr>
    
    )
  }
}
