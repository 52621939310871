import React, { Component } from "react";
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Col, Row, Form} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Navigate } from "react-router-dom";


import strBEURL from './backend-url';

//let strBEURL = 'https://clammytest.assertions.ca'   // back end url for prod  BE
// strBEURL = 'http://localhost:3030'
// this will use BE get model /amodel/user-model/  which will expect the cookie to be in place
let blnInvalidToken = false

const showFeedback = ((feedback,place) => {
  if (document.getElementById(place) ){
    document.getElementById(place).innerHTML = feedback
  }
})



export default class EditModel extends Component {
constructor(props) {
    super(props)
  
    this.onChangeModelName = this.onChangeModelName.bind(this);
    this.onChangeModelTitle = this.onChangeModelTitle.bind(this);
    this.onChangeModelIntroduction = this.onChangeModelIntroduction.bind(this);

    this.onChangeModelDomainSummary = this.onChangeModelDomainSummary.bind(this);
    this.onChangeModelDesignerNote = this.onChangeModelDesignerNote.bind(this);
    this.onChangeModelVersion = this.onChangeModelVersion.bind(this);
    this.onChangeModelModeler = this.onChangeModelModeler.bind(this);
    this.viewHTML = this.viewHTML.bind(this);

    this.onSubmit = this.onSubmit.bind(this);

    // State
    this.state = {
      Name: '',
      Title : '',
      Introduction: '' ,
      DomainSummary: '',
      DesignerNote: '',
      Version: '',
      Modeler: '',
    }
  }

   
  componentDidMount() {
   

    let strParam = "?token=" + localStorage.getItem('USER_MODEL_TOKEN') 
    axios.get( strBEURL + '/amodel/user-model/' + strParam)
    .then(res => {
// Divert to login if no token
// res.data.model.Name
    if (res.data.model.Name) { //  indicate divert to login
      blnInvalidToken = false
    } else {
      blnInvalidToken = true  // this is used at start of render
     

    }


        if (document.getElementById("messagePlace") ){
            document.getElementById("messagePlace").innerHTML =  res.data.message + '  -   ' + res.data.model.Name;
        }
        this.setState({
          Name: res.data.model.Name,
          Title: res.data.model.Title, 
          ModelID: res.data.model.ModelID,
          Introduction: res.data.model.Introduction,
          DomainSummary: res.data.model.DomainSummary,
          DesignerNote: res.data.model.DesignerNote,
          Version: res.data.model.Version,
          Modeler: res.data.model.Modeler,
          message: res.data.model.message,   // assume a message is sent .  Might be undefined??
          token: localStorage.getItem('USER_MODEL_TOKEN'),
          hyperURL: ''  // calc when called
        });
 
    })
    .catch((error) => {

        if (document.getElementById("messagePlace") ){
            document.getElementById("messagePlace").innerHTML = 'ERR: '+ error;
        }
    console.log(error);
    })
//  now it should be possible to access the model object   
  };

  onChangeModelName(e) {this.setState({ Name: e.target.value })}
  onChangeModelTitle(e) {this.setState({ Title: e.target.value })}
  onChangeModelIntroduction(e) {this.setState({ Introduction: e.target.value })}

  onChangeModelDomainSummary(e) {this.setState({ DomainSummary: e.target.value })}
  onChangeModelDesignerNote(e) {this.setState({ DesignerNote: e.target.value })}
  onChangeModelVersion(e) {this.setState({ Version: e.target.value })}
  onChangeModelModeler(e) {this.setState({ Modeler: e.target.value })}

  viewHTML(e) {  // send to BE to bulid and go to it
  
    let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
    let url =   strBEURL + '/hyperrep/fullhtml/' + strParams

    //if (document.getElementById("messagePlace") ){
     // document.getElementById("messagePlace").innerHTML =  'Creating Hyperlinked Report. About 15 seconds...';
  //}
    showFeedback('Creating Hyperlinked Report. About 15 seconds...',"messagePlace")

    axios.get(url)
    .then((res) => {
      this.setState({
        hyperURL: res.data
      });

      if (res.data.STATUS === "OK") {
        showFeedback('Hyperlink saved to: ' + res.data.MESSAGE,"messagePlace")

            const now = new Date(); // this to make url unique each render so FE refreshes
            window.location.assign( res.data.PAYLOAD.repurl +  '?' + now.toISOString() )   // now causes time to be added to url so it will refresh
      }else{
        showFeedback('Unable to save with message: ' + res.data.MESSAGE,"messagePlace")
      }
   
    })
    .catch((error) => {
      showFeedback('Unable to save with error: ' + error,"messagePlace")

      this.setState({
        hyperURL: error
      });
    })
    

}

onSubmit(e) {
    e.preventDefault()

    document.getElementById("messagePlace").innerHTML = " UPDATING  " //+ this.state.Name ; 

    const modelObject = {
      ModelID:  this.state.ModelID,              // get these 2 values
      token: this.state.token ,
      Name: this.state.Name,
      Title: this.state.Title,
      Introduction: this.state.Introduction,
      DomainSummary: this.state.DomainSummary,
      DesignerNote: this.state.DesignerNote,
      Version: this.state.Version,
      Modeler: this.state.Modeler
    }

//  make the payload for a put

    let mod = {ModelID: modelObject.ModelID,
      Name: modelObject.Name,
      Title: modelObject.Title,
      Introduction:  modelObject.Introduction,
      DomainSummary:  modelObject.DomainSummary,
      DesignerNote:  modelObject.DesignerNote,
      Version:  modelObject.Version,
      Modeler:  modelObject.Modeler,
    }


    document.getElementById("messagePlace").innerHTML = " UPDATING  " + modelObject.Name//+ this.state.Name ; 

    axios.put( strBEURL + '/amodel/update-model/' , mod) 
    .then((res) => {
      document.getElementById("messagePlace").innerHTML = " UPDATED  " + modelObject.Name   // should find the element below??
    })
    .catch(function (error) {
      //console.log(error)
      document.getElementById("messagePlace").innerHTML = "Failed UPDATE: " + modelObject.Name + ".   With error: "+ error // should find the element below??  // this does update when the 
    })

}

getHTMLForAssnImpEnvs(arrImpEnvAssn) {   // this test works OK
  let assnImpEnvs = {}
  arrImpEnvAssn.forEach(aiea =>{
    let thisAssnGUID = aiea.AssnStrGUID
    if (!assnImpEnvs.hasOwnProperty(thisAssnGUID)){assnImpEnvs[thisAssnGUID]= aiea.AssnSpecID + aiea.AssnName  + '<br><br>\n' }   // add if not there
    assnImpEnvs[thisAssnGUID] += '&nbsp; &nbsp;  ' + aiea.ImplName + ': ' + aiea.EnvName
  })
  //when comes out assnImpEnvs in an object that we just need the values -- use index ??
  let ans = ''
  for (const [key, value] of Object.entries(assnImpEnvs)) {
    ans += value
  }
  return ans 
}



render() {
    //var inputConnections = this.state.InputConnections

// add a test bed for ImpEnv
// {this.getHTMLForAssnImpEnvs([{ AssnStrGUID: 'ioeiwnnd' , AssnSpecID: 'RC0n' ,   AssnName: 'Interview Expenses Claim' , ImplName: 'Current State', EnvName: 'Office Tools'}] )}
  if (blnInvalidToken) { // divert to login
    return <Navigate to="/login" />;
  }


    return (<div className="form-wrapper">


<Row>
  <Col lg={9}> 
    <p class="paraModel"><font size="6" color="navy"><b>{this.state.Name }</b>  </font></p>
    <p class="paraModel"><font size="5" color="navy"> {this.state.Title } </font></p>
 
  
  </Col>
  <Col lg={3}> 
  <p class="paraModel" align="right">
    <Link
      className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
      to={'/explain/' + 'KN244'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
      >
      Explain&#62;
    </Link>
    <Button onClick={this.viewHTML} size="sm" variant="success">
            View HTML
    </Button>

   
</p>

<p class="paraModel" align="right"><font size="2" color="navy"> {this.state.ModelID } </font></p>
<p class="paraModel" align="right"><font size="2" color="black">Modeler:    {this.state.Modeler } </font> </p>
<p class="paraModel" align="right"><font size="2" color="black">  Version:  {this.state.Version }</font> </p>

  </Col>
</Row>
  <Form  class="form-inline" onSubmit={this.onSubmit}> 
  <Row>
    <span class="square border border-4">
        <Col >
        <b>Model</b>  
            <Form.Group  controlId="Name" class="list-inline  float-right">
                <Form.Label   class="float-right w-50" >Model Name
                        <Form.Control type="text" value={this.state.Name } onChange={this.onChangeModelName } />
                </Form.Label>
            </Form.Group>
            <Form.Group controlId="Title" >
                <Form.Label>Title </Form.Label>
                        <Form.Control type="text" value={this.state.Title } onChange={this.onChangeModelTitle } />
            </Form.Group>
            <Form.Group controlId="Introduction">
                <Form.Label>Introduction</Form.Label>
                        <Col>
                        <Form.Control 
                        as="textarea" 
                        value={this.state.Introduction }
                        onChange={this.onChangeModelIntroduction }
                    
                        rows={3} /> 
                        </Col>
            </Form.Group>
            <Form.Group controlId="DomainSummary">
                <Form.Label>Domain Summary</Form.Label>
                        <Col>
                        <Form.Control 
                        as="textarea" 
                        value={this.state.DomainSummary }
                        onChange={this.onChangeModelDomainSummary }
                    
                        rows={3} /> 
                        </Col>
            </Form.Group>
            <Form.Group controlId="DesignerNote">
                <Form.Label>Designer Note</Form.Label>
                        <Col>
                        <Form.Control 
                        as="textarea" 
                        value={this.state.DesignerNote }
                        onChange={this.onChangeModelDesignerNote }
                    
                        rows={3} /> 
                        </Col>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="Version">
                  <Form.Label>Version</Form.Label>   
                  <Form.Control 
                    as="textarea" 
                    value={this.state.Version }
                    onChange={this.onChangeModelVersion }
                
                    rows={1} /> 
                 </Form.Group>        
              </Col>
              <Col> 
                <Form.Group controlId="Modeler">
                  <Form.Label>Modeler</Form.Label>
                    <Form.Control 
                      as="textarea" 
                      value={this.state.Modeler }
                      onChange={this.onChangeModelModeler }
                  
                      rows={1} />   
                </Form.Group>
              </Col>
            </Row>
        </Col>
    </span>
  </Row>
    <Button variant="danger" size="lg" block="block" type="submit" className="mt-4">
        Update Model
    </Button>
    <div id="messagePlace" class="alert alert-primary" role="alert">
                    Model messages will appear here..
    </div>
</Form>


</div>
    )
}   // end of render function
}  // end EditModel Component