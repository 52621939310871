import React, { Component } from "react";
import { Col, Row, Form, Button, Table, ListGroup} from "react-bootstrap";
import axios from 'axios';
import { createHashRouter, Link} from "react-router-dom";

import IEAOptionRow from './IEAOptionsRow';
import EnvironmentOptionRow from './EnvironmentOptionsRow';
import ImplementationOptionRow from './ImplementationOptionsRow';
import IEAListGroupRow from './IEAListGroupRow';

import strBEURL from './backend-url';

//let strBEURL = 'https://clammytest.assertions.ca'   // back end url for prod  BE
// strBEURL = 'http://localhost:3030'
// a utility fn
const showFeedback = ((feedback,place) => {   // used to indicate sucess of crud actions
    // These should be 
  if (document.getElementById(place) ){
    document.getElementById(place).innerHTML = feedback
  }
})
let thisAssnGUID 
let arrEnv
let arrTri
let arrImpl

export default class EditImplementation extends Component {
    // expects dbid or strAssnGUID to allow populating state
    constructor(props) {
        super(props)
        this.state = {
            ListIEA: [],            //  the implenvassn rows that match the assertion dbid  - might be empty
            ListImplementations: [],   // all the implememntations (and details)
            ListEnvironments: [],    // all the environments and details 
            SelectedImplDBID: 0,    // ids used to update records
            SelectedEnvDBID: 0,
            SelectedTriDBID: 0,
        }

        this.onChangeTriDescription = this.onChangeTriDescription.bind(this)  // these must be in the constructor
        this.onChangeTriModelNote = this.onChangeTriModelNote.bind(this) 
        this.onChangeImplName = this.onChangeImplName.bind(this) 
        this.onChangeImplDescription = this.onChangeImplDescription.bind(this) 
        this.onChangeImplModelNote = this.onChangeImplModelNote.bind(this) 

        this.onChangeEnvName = this.onChangeEnvName.bind(this) 
        this.onChangeEnvDescription = this.onChangeEnvDescription.bind(this) 
        this.onChangeEnvModelNote = this.onChangeEnvModelNote.bind(this)   

        this.EnvSelected = this.EnvSelected.bind(this)   
        this.ImplSelected = this.ImplSelected.bind(this)  
        this.TriSelected = this.TriSelected.bind(this)  
        
    }

    componentDidMount() {
        this.fetchIEAs(this.props.dbid,this.props.AssnStrGUID)
      }


// fetch gets 3 axios into the state   // EXAMPLES BELOW  (from Postman)
// the fetch should create dictionaries with the dbid as the key
exampleImpl = {
    "id": 3,
    "ModelID": "RecModAP",
    "Name": "AsIs",
    "Description": "The current state",
    "ModelNote": "blank",
    "strImplGUID": "334dda5e-e910-404b-b93f-2da445859930",
    "createdAt": "2024-08-05T16:48:07.000Z",
    "updatedAt": "2024-08-05T16:48:07.000Z"
}
exampleEnv =     {
    "id": 1,
    "ModelID": "RecModAP",
    "Name": "Office Tools",
    "Description": "Use of Excel, Word, Outlook etc",
    "ModelNote": "This is a trial record for testing",
    "strEnvGUID": "984hnfdo989u5",
    "createdAt": "2024-07-06T10:17:34.000Z",
    "updatedAt": "2024-07-06T10:17:34.000Z"
}
exampleTri =    
    {
        "AssnStrGUID": "f5cafa33-97ca-461b-86e4-8516ac85884e",
        "ModelID": "RecModAP",
        "AssnDBID": 12164,
        "AssnName": "Effective Position Management",
        "AssnSpecID": "EX01",
        "ImplStrGUID": "98(*779&^678887798jjjyfgf",
        "ImplDBID": 4,
        "ImplName": "ToBe",
        "ImplDescription": "Glorious Future",
        "ImplModelNote": "dd",
        "EnvStrGUID": "NewSys)(9887689JNGGhjyei",
        "EnvDBID": 3,
        "EnvName": "Great New System",
        "EnvDescription": "Sing and Dance",
        "EnvModelNote": null,
        "TriDescription": "Fully automated",
        "TriModelNote": "showme",
        "TriDBID": 3
    }


fetchIEAs(dbid , assnstrguid) {

    let strParams = "token=" + localStorage.getItem('USER_MODEL_TOKEN')
    axios.all([     
        axios.get( strBEURL + '/implenv/get-assertion-ie-list/?dbid=' + dbid), // the tri for this dibid  // this now returns AssnStrGUID too
        axios.get( strBEURL + '/implenv/get-implementations/?' + strParams),  // the full list of imps for thois model
        axios.get(  strBEURL + '/implenv/get-environments/?' + strParams), // the full list of envs for thois model
    ])
    .then(axios.spread((ieas, implementations, environments) => {   // how do we catch these individually

        console.log('Impls Fetched')
        console.log(JSON.stringify(ieas.data))
        // set selected values 
            let dictTri ={}   // access the tri by its dbid 
        if (ieas.data.length > 0) {
            let cleanIEAS  = JSON.parse(JSON.stringify(ieas)).data // assume a sequellize invisible fiddle
        
            arrTri = cleanIEAS.ArrImpEnv
            thisAssnGUID = cleanIEAS.AssnStrGUID  // finally got this here


            arrTri.forEach(tri => {
                dictTri[tri.TriDBID]= tri
            })
        }

  

        arrImpl = JSON.parse(JSON.stringify(implementations)).data // assume a sequellize invisible fiddle
        let dictImpl ={}
        arrImpl.forEach(impl => {
            dictImpl[impl.id]= impl
        })

         arrEnv = JSON.parse(JSON.stringify(environments)).data // assume a sequellize invisible fiddle
        let dictEnv ={}
        arrEnv.forEach(env => {
            dictEnv[env.id]= env
        })

        let selectedTri = -1       // this should ensure there is a selected value in the comboeboxes  this is the index in the select
        let selectedEnv = -1 
        let selectedImpl = -1
        if (arrTri) {
            if (arrTri.length > 0) {selectedTri = 0 }
        }
        if (arrEnv.length > 0) {selectedEnv = 0 }
        if (arrImpl.length > 0) {selectedImpl = 0 }
       

        this.setState({
            ListIEA: dictTri,  
            ListEnvironments: dictEnv,
            ListImplementations: dictImpl,
            // at this point the three objects are populated from the objects in the db as ke value pairs
            SelectedTriDBID: selectedTri,
            SelectedEnvDBID: selectedEnv,
            SelectedImplDBID: selectedImpl,
            AssnStrGUID: assnstrguid,    // now we have this it originates in assertion list, passed to edit assertion , passed to impenv component, and ready here to be used to populate new tri's
          })

    }))
    .catch((error) => {
        showFeedback('ERROR in fetching implementations information: ' + error,'messagePlaceTris')
      })

    return ''  // from fetch  -- state is refreshed
  }
  
  
 // ================== ONCHANGE FUNCTIONS ====================================================================
// for impls these are 3 selections and one on change for each entryfield


onChangeTriDescription(e) {
    let dictCurrent = this.state.ListIEA
    dictCurrent[this.state.SelectedTriDBID]["TriDescription"] = e.target.value
    this.setState({ ListIEA: dictCurrent })
}
onChangeTriModelNote(e) {
    let dictCurrent = this.state.ListIEA
    dictCurrent[this.state.SelectedTriDBID]["TriModelNote"] = e.target.value
    this.setState({ ListIEA: dictCurrent })
} 

onChangeImplName(e) {
    let dictCurrent = this.state.ListImplementations
    dictCurrent[this.state.SelectedImplDBID]["Name"] = e.target.value
    this.setState({ ListImplementations: dictCurrent })
} 

onChangeImplDescription(e) {
    let dictCurrent = this.state.ListImplementations
    dictCurrent[this.state.SelectedImplDBID]["Description"] = e.target.value
    this.setState({ ListImplementations: dictCurrent })
} 

onChangeImplModelNote(e) {
    let dictCurrent = this.state.ListImplementations
    dictCurrent[this.state.SelectedImplDBID]["ModelNote"] = e.target.value
    this.setState({ ListImplementations: dictCurrent })
} 

onChangeEnvName(e) {
    let dictCurrent = this.state.ListEnvironments
    dictCurrent[this.state.SelectedEnvDBID]["Name"] = e.target.value
    this.setState({ ListEnvironments: dictCurrent })
} 

onChangeEnvDescription(e) {
    let dictCurrent = this.state.ListEnvironments
    dictCurrent[this.state.SelectedEnvDBID]["Description"] = e.target.value
    this.setState({ ListEnvironments: dictCurrent })
} 

onChangeEnvModelNote(e) {
    let dictCurrent = this.state.ListEnvironments
    dictCurrent[this.state.SelectedEnvDBID]["ModelNote"] = e.target.value
    this.setState({ ListEnvironments: dictCurrent })
}


   // === ======  HANDLER FUNCTIONS =======================================================

TriSelected = (e) => {
    //console.log("TriSelected with event: " + e) 
// also should hange th Selected Imp and env by getting the ImplDIBID from the oject selected
// the dict in 

    let thisTri = this.state.ListIEA[Number(e.target.value)]
    //console.log (thisTri)  // shd be an object
    let envDBID =thisTri["EnvDBID"]
    let implDBID =thisTri["ImplDBID"]
    //let envDBID = JSON.parse(JSON.stringify(thisTri))["EnvDBID"]
    console.log(' setting SelectedEnvDBID= ' +  envDBID)
    console.log(' setting SelectedEImplDBID= ' +  implDBID)
    this.setState({   SelectedTriDBID: Number(e.target.value), // set the tri dbid so tri fields update
        SelectedEnvDBID: Number(envDBID),
        SelectedImplDBID: Number(implDBID)
    })   // this should ripple down to the other 2 combo boxes

}

ImplSelected = (e) => {
    this.setState({   SelectedImplDBID: Number(e.target.value)}) 
    console.log("ImplSelected with event: " + Number(e.target.value) )
}

EnvSelected = (e) => {
    console.log("before set state"  + "\n this.state.SelectedEnvDBID: " + this.state.SelectedEnvDBID)  
    let huh = 0
     huh = Number(e.target.value)
    this.setState({   SelectedEnvDBID: huh}) // this does not seem to work
    console.log("EnvSelected with dbid: " + createHashRouter + "\n this.state.SelectedEnvDBID: " + this.state.SelectedEnvDBID)  

// it seems that React does not execute these state changes immediately but batches 
    this.setState({SelectedEnvDBID: Number(e.target.value)}, function () {
        console.log(this.state.SelectedEnvDBID);
    });


}


// ============== CRUDS FOR  IMPL ENV and ASSNIMPL
AddImplementation = () => {
    // I think this was tested in Pman
    let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
    showFeedback('Adding a new  Implementation..','messagePlaceTris')
    axios
      .get( strBEURL + '/implenv/create-implementation/' + strParams ,)
      .then((res) => {     // res.data.message and res.data.payload are avail
        if (res.data.message === 'OK') {
            showFeedback('.. Saved new Implementation','messagePlaceTris')
            this.setState({     // this should change selection to new value
                SelectedImplDBID: res.data.payload.dbid
              })
        } else {
            showFeedback('Unable to save new Implementation.','messagePlaceTris')
        }
      })
      .catch((error) => {
        showFeedback('Error saving new Implementation: ' + error,'messagePlaceTris')
      })

}

UpdateImplementation = () => {
    showFeedback('Updating  Implementation..','messagePlaceTris')
    let strParams =''
// need to capture empty here
    if (!this.state.SelectedImplDBID) {
        showFeedback('.. cannot update. No  implementation has been selected.  ','messagePlaceTris');
        return  
    }
    strParams += '?implementationDBID=' +    this.state.SelectedImplDBID
    strParams += '&implementationName=' +    this.state.ListImplementations[this.state.SelectedImplDBID].Name
    strParams += '&implementationDescription=' +    this.state.ListImplementations[this.state.SelectedImplDBID].Description
    strParams += '&implementationModelNote=' +    this.state.ListImplementations[this.state.SelectedImplDBID].ModelNote

// now use the endpoint
axios.get( strBEURL + '/implenv/update-implementation/' + strParams )
.then((res) => {     // res.data.message and res.data.payload are avail
  if (res.data.message === 'OK') {
      showFeedback('.. Updated  Implementation ','messagePlaceTris')
      // now can update state and refresh(shd occur auto)
  } else {
      showFeedback('Unable to update   implementation. Message: ' + res.data.message,'messagePlaceTris')
  }
})
.catch((error) => {
  showFeedback('Error updating implementation. error: ' + error,'messagePlaceTris')
})


}

DeleteImplementation = (e) => {
    showFeedback('Delete Implementation started... ','messagePlaceTris')

    let strParams =''
    // need to capture empty here
        if (!this.state.SelectedImplDBID) {
                    showFeedback('.. cannot delete.  No implementation has been selected.  ','messagePlaceTris');
            return  
        }
        strParams += '?implementationDBID=' +    this.state.ListImplementations[this.state.SelectedImplDBID].id
 
        axios.get( strBEURL + '/implenv/delete-implementation/' + strParams )
          .then((res) => {     // res.data.message and res.data.payload are avail
            if (res.data.message === 'OK') {
                showFeedback('.. Deleted  Implementation ','messagePlaceTris')
         
            } else {
                showFeedback('Unable to delete   implementation. Message: ' + res.data.message,'messagePlaceTris')
            }
          })
          .catch((error) => {
            showFeedback('Error deleting implementation. error: ' + error,'messagePlaceTris')
          })
}


AddEnvironment = () => {
    // I think this was tested in Pman
    let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
    showFeedback('Adding a new  Environment...','messagePlaceTris')
    axios
      .get( strBEURL + '/implenv/create-environment/' + strParams ,)
      .then((res) => {     // res.data.message and res.data.payload are avail
        if (res.data.message === 'OK') {
            showFeedback('.. Saved new Environment','messagePlaceTris')
            this.setState({     // this should change selection to new value
                SelectedImplDBID: res.data.payload.dbid
              })
        } else {
            showFeedback('Unable to save new Environment.','messagePlaceTris')
        }
      })
      .catch((error) => {
        showFeedback('Error saving new Environment: ' + error,'messagePlaceTris')
      })
}

UpdateEnvironment = () => {
    showFeedback('Updating  Environment..','messagePlaceTris')
    console.log('state: ' + JSON.stringify(this.state))  // is this ready to use for update?
    let strParams =''
// need to capture empty here
    if (arrEnv.length<1) {
        showFeedback('.. cannot update. No  Environments created for this model.  ','messagePlaceTris');
        return  
    }

let huh = this.state.SelectedImplDBID
let wha = isNaN(this.state.SelectedImplDBID)
    if (isNaN(this.state.SelectedImplDBID)) {
        showFeedback('.. cannot update. Bad selection.  ','messagePlaceTris');
        return  
    }
    if (this.state.SelectedImplDBID<0) {
        showFeedback('.. cannot update. No  Environment has been selected.  ','messagePlaceTris');
        return  
    }
    strParams += '?EnvironmentDBID=' +    this.state.SelectedEnvDBID
    strParams += '&EnvironmentName=' +    this.state.ListEnvironments[this.state.SelectedEnvDBID].Name
    strParams += '&EnvironmentDescription=' +    this.state.ListEnvironments[this.state.SelectedEnvDBID].Description
    strParams += '&EnvironmentModelNote=' +    this.state.ListEnvironments[this.state.SelectedEnvDBID].ModelNote
    showFeedback('Sending:   ' + strParams,'messagePlaceTris');
    // now use the endpoint
    axios.get( strBEURL + '/implenv/update-environment/' + strParams )
    .then((res) => {     // res.data.message and res.data.payload are avail
    if (res.data.message === 'OK') {
        showFeedback('.. Updated  environment ','messagePlaceTris')
        // now can update state and refresh(shd occur auto)
    } else {
        showFeedback('Unable to update environment for:' + strParams +    '.  Message: ' + res.data.message,'messagePlaceTris')
    }
    })
    .catch((error) => {
    showFeedback('Error updating environmentfor: ' + strParams +    '.  error: ' + error,'messagePlaceTris')
    })
}

DeleteEnvironment = (e) => {
    showFeedback('Delete environment started... ','messagePlaceTris')

    let strParams =''
    // need to capture empty here
        if (!this.state.SelectedEnvDBID) {
                    showFeedback('.. cannot delete.  No environment has been selected.  ','messagePlaceTris');
            return  
        }
        strParams += '?environmentDBID=' +    this.state.ListEnvironments[this.state.SelectedEnvDBID].id
 
        axios.get( strBEURL + '/implenv/delete-environment/' + strParams )
          .then((res) => {     // res.data.message and res.data.payload are avail
            if (res.data.message === 'OK') {
                showFeedback('.. Deleted  environment ','messagePlaceTris')
         
            } else {
                showFeedback('Unable to delete   environment. Message: ' + res.data.message,'messagePlaceTris')
            }
          })
          .catch((error) => {
            showFeedback('Error deleting environment. error: ' + error,'messagePlaceTris')
          })
}

UpdateAssnImpl = () => {
   
    showFeedback('Updating Assertion Implementation..','messagePlaceTris')
    let strParams =''
// need to capture empty here
    if (!this.state.SelectedTriDBID) {
                showFeedback('.. cannot update. No assertion implementation has been selected.  ','messagePlaceTris');
        return  
    }
    strParams += '?assnImpDBID=' +    this.state.ListIEA[this.state.SelectedTriDBID].TriDBID
    strParams += '&assnImpDescription=' +    this.state.ListIEA[this.state.SelectedTriDBID].TriDescription
    strParams += '&assnImpModelNote=' +    this.state.ListIEA[this.state.SelectedTriDBID].TriModelNote

   console.log('Update strParams: ' + strParams)
    axios.get(  strBEURL + '/implenv/update-implementation/' + strParams )
      .then((res) => {     // res.data.message and res.data.payload are avail
        if (res.data.message === 'OK') {
            showFeedback('.. Updated Assertion Implementation ','messagePlaceTris')
            // now can update state and refresh(shd occur auto)
        } else {
            showFeedback('Unable to update  assertion implementation. Message: ' + res.data.message,'messagePlaceTris')
        }
      })
      .catch((error) => {
        showFeedback('Error updating assertion implementation. error: ' + error,'messagePlaceTris')
      })
}

DeleteAssnImpl = (e) => {
    showFeedback('Delete started... ','messagePlaceTris')

    let strParams =''
    // need to capture empty here
        if (!this.state.SelectedTriDBID) {
                    showFeedback('.. cannot delete.  No assertion implementation has been selected.  ','messagePlaceTris');
            return  
        }
        strParams += '?assnImpDBID=' +    this.state.ListIEA[this.state.SelectedTriDBID].TriDBID
 
        axios.get(  strBEURL + '/implenv/delete-assnimpl/' + strParams )
          .then((res) => {     // res.data.message and res.data.payload are avail
            if (res.data.message === 'OK') {
                showFeedback('.. Deleted Assertion Implementation ','messagePlaceTris')
                // now can update state and refresh(shd occur auto)
            } else {
                showFeedback('Unable to delete  assertion implementatin. Message: ' + res.data.message,'messagePlaceTris')
            }
          })
          .catch((error) => {
            showFeedback('Error deleting assertion. error: ' + error,'messagePlaceTris')
          })
}

AddAssnImpl = () => {
   
    showFeedback('Adding a new Assertion Implementation..','messagePlaceTris')
// This should send axis and get result  then add to state  - set state  using create-implementation
   
    let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')

    if (!this.state.SelectedImplDBID || !this.state.SelectedEnvDBID) {
        showFeedback('.. Cannot add. Implementation and Environment must be selected.','messagePlaceTris')
        return
    }

    // this needs the implGUID not the id
    strParams += '&strImplGUID=' +   this.state.ListImplementations[this.state.SelectedImplDBID].strImplGUID
    strParams += '&strEnvGUID=' +   this.state.ListEnvironments[this.state.SelectedEnvDBID].strEnvGUID

    //let myKey = Object.getOwnPropertyNames(this.state.ListIEA)[0]   // use this to get the assnGUID from one 

 

    strParams += '&strAssnGUID=' +  thisAssnGUID
    strParams += '&assnImpDescription=tbd'
    strParams += '&assnImpModelNote=tbd' 

    console.log( ' strParams for new tri is: ' + strParams)

    axios
      .get(  strBEURL + '/implenv/create-assnimpl/' + strParams ,)
      .then((res) => {     // res.data.message and res.data.payload are avail
        if (res.data.message === 'OK') {
            showFeedback('.. Saved new Assertion Implementation with dbid: '+ res.data.payload ,'messagePlaceTris')
            // now can update state and refresh(shd occur auto)

        } else {
            showFeedback('Unable to save new assertion. Message: ' + res.data.message,'messagePlaceTris')
        }

      })
      .catch((error) => {
        showFeedback('Error saving new assertion. error: ' + error,'messagePlaceTris')
      })
      
}


 // ================== ListComponent FUNCTIONS ====================================================================

IEAOptionList(arrIEAS) {      // not sure this is needed
  if(arrIEAS.length ){
    let arrIEAs = Object.values(arrIEAS)   // ieas has to be massaged with key value  - no i is jest the index
      return arrIEAs.map((obj, i) => { 
        return <IEAOptionRow obj={obj} key={i} />;
      });
  } 
}

IEAListGroupList(arrIEAS) {
  if(arrIEAS.length ){
    let arrIEAsO = Object.values(arrIEAS)   // ieas has to be massaged with key value  - no i is jest the index
      let strCmpIEAS = arrIEAsO.map((obj, i) => { 
        return <IEAListGroupRow obj={obj} key={i} />;
      });
      //console.log ('huh')
      console.log (strCmpIEAS)
      return strCmpIEAS
  } else {return 'No AssnImplementations'}
}

ImplementationsOptionList(arrImplementations) {
  if(arrImplementations.length ){
    let arrImplementationsO = Object.values(arrImplementations)   // ieas has to be massaged with key value  - no i is jest the index
    let strCmpImpl =  arrImplementationsO.map((obj, i) => { 
        return <ImplementationOptionRow obj={obj} key={i} />;
      });
      //console.log ('ehh')
      //console.log (strCmpImpl)
      return strCmpImpl



  } 
}

EnvironmentsOptionList(arrEnvironments) {
  if(arrEnvironments.length ){
    let arrEnvironmentsO = Object.values(arrEnvironments)   // ieas has to be massaged with key value  - no i is jest the index
      return arrEnvironmentsO.map((obj, i) => { 
        return <EnvironmentOptionRow obj={obj} key={i} />;
      });
  } 
}



// selections  - these cause no BE actions but chang what is shown
// select tri  
// select impl
// select env

// actions (buttons)   -- these change state for the 3 collections after sucessful axios update of the BE
// tri - add tri -- axios insert and then state update and selection update
// tri - delete  --  similar
// tri - update  -- similar

// as above for the impl and env

    render() {

        let myTri= this.state.ListIEA[this.state.SelectedTriDBID] 
        let assnSpecID = ''
        let strTriModelNote 
        let strTriDescription
        if (myTri)
        {assnSpecID = myTri["AssnSpecID"]
        strTriModelNote = myTri["TriModelNote"]
        strTriDescription = myTri["TriDescription"]
        }     // works

        //  Implemtations
        let myImpl= this.state.ListImplementations[this.state.SelectedImplDBID] 
        let strImplName
        let strImplDescription
        let strImplModelNote 
        if (myImpl)
        {strImplName = myImpl["Name"]
        strImplModelNote = myImpl["ImplModelNote"]
        strImplDescription = myImpl["Description"]
        }     // 

        //  Environments
        let myEnv= this.state.ListEnvironments[this.state.SelectedEnvDBID] 
        let strEnvName
        let strEnvDescription
        let strEnvModelNote 
        if (myEnv)
        {strEnvName = myEnv["Name"]
        strEnvModelNote = myEnv["ModelNote"]
        strEnvDescription = myEnv["Description"]
        }     // 

        return (
            <Row>
                <span class="square border border-4">
                     <p align="right">
                        <Link
                            className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}   // path is not defied for Llink and may not be necessary
                            to={'/explain/KN60'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
                        >
                            Explain&#62;
                        </Link>
                    </p>
                    <p><b><i><font size="5" color="black">{assnSpecID} Implementations</font></i></b></p> 
                   
                    <Col>
                        <Row>
                            <Col xs={4}> 
                            <div style={{fontSize: 18, padding: 2 , color: "navy", fontWeight: "bold"}}>
                                    Assertion Implementations 
                                </div>
                                <Form.Select 
                                    size="lg"    
                                    as="select" 
                                    custom  
                                    onChange={this.TriSelected}
                                    value={this.state.SelectedTriDBID}
                                    style={{ fontSize: 18, padding: 2 , color: "navy", width: "100%"}}
                                >
                                     {this.IEAListGroupList(Object.values(this.state.ListIEA))}

                                </Form.Select>

                                <Row>
                                    <Col xs={6}> 
                                        <Button  size="sm" variant="primary"   type="button" className="mt-2" onClick={this.AddAssnImpl}>
                                            Add New Implementation for this Assertion
                                        </Button>
                                    </Col>
                                    <Col xs={3}> 
                                        <Button  size="sm" variant="danger"  block="block" type="submit" className="mt-2"  onClick={this.DeleteAssnImpl}>
                                            Delete
                                        </Button>
                                    </Col>
                                    <Col xs={3}>
                                    <Button  size="sm" variant="warning"  block="block" type="submit" className="mt-2" onClick={this.UpdateAssnImpl}>
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                                <Form.Group controlId="TriDescription" >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control 
                                        type="text"                         
                                        as="textarea" 
                                        rows={3}   
                                        value={strTriDescription } 
                                        onChange={this.onChangeTriDescription } />
                                </Form.Group>
                                <Form.Group controlId="TriModelNote" >
                                    <Form.Label>Impl Model Note </Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        rows={2}   
                                       value={strTriModelNote}
                                        onChange={this.onChangeTriModelNote } 
                                    />
                                </Form.Group> 

                            </Col>   
                            <Col xs={4}>
                                <div style={{fontSize: 18, padding: 2 , color: "purple", fontWeight: "bold"}}>
                                    All Implementations
                                </div>
                                <Form.Select
                              
                                    size="lg"    
                                    as="select" 
                                    custom  
                                    onChange={this.ImplSelected}
                                    value={this.state.SelectedImplDBID}
                                    style={{ fontSize: 18, padding: 2 , color: "purple", width: "100%"}}
                                >
                                    {this.ImplementationsOptionList(Object.values(this.state.ListImplementations))}
                                </Form.Select>
                                <Row>
                                    <Col xs={6}> 
                                        <Button  size="sm" variant="primary"  block="block" type="submit" className="mt-2"  onClick={this.AddImplementation}>
                                            Add Implementation
                                        </Button>
                                    </Col>
                                    <Col xs={3}> 
                                        <Button  size="sm" variant="danger"  block="block" type="submit" className="mt-2"  onClick={this.DeleteImplementation}>
                                            Delete
                                        </Button>
                                    </Col>
                                    <Col xs={3}>
                                    <Button  size="sm" variant="warning"  block="block" type="submit" className="mt-2"  onClick={this.UpdateImplementation}>
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                                <Form.Group controlId="ImplName" >
                                    <Form.Label>Implementation Name</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        rows={1}   
                                       value={strImplName}
                                        onChange={this.onChangeImplName } 
                                    />
                                </Form.Group> 
                                <Form.Group controlId="ImplDescription" >
                                    <Form.Label>Impelemetation Description</Form.Label>
                                    <Form.Control 
                                        type="text"                         
                                        as="textarea" 
                                        rows={2}   
                                        value={strImplDescription } 
                                        onChange={this.onChangeImplDescription } />
                                </Form.Group>
                                <Form.Group controlId="ImplModelNote" >
                                    <Form.Label>Implementation Model Note</Form.Label>
                                    <Form.Control 
                                        type="text"                         
                                        as="textarea" 
                                        rows={2}   
                                        value={strImplModelNote } 
                                        onChange={this.onChangeImplModelNote } />
                                </Form.Group>

                                                                        {/** Dont forget this is f**king JSX */}
                           
                            </Col>
                            <Col xs={4}>
                                <div style={{fontSize: 18, padding: 2 , color: "darkred", fontWeight: "bold"}}>
                                     All Environments
                                </div>
                                <Form.Select 
                                    size="lg"    
                                    as="select" 
                                    custom  
                                    onChange={this.EnvSelected}
                                    style={{ fontSize: 18, padding: 2 , color: "navy", width: "100%"}}
                                >)
                                    {this.EnvironmentsOptionList(Object.values(this.state.ListEnvironments))}
                                </Form.Select>
                                <Row>
                                    <Col xs={6}> 
                                        <Button   size="sm" variant="primary" block="block" type="submit" className="mt-2"   onClick={this.AddEnvironment}>
                                            Add Environment
                                        </Button>
                                    </Col>
                                    <Col xs={3}> 
                                        <Button   size="sm" variant="danger"  block="block" type="submit" className="mt-2"    onClick={this.DeleteEnvironment}>
                                            Delete
                                        </Button>
                                    </Col>
                                    <Col xs={3}>
                                    <Button   size="sm" variant="warning"  block="block" type="submit" className="mt-2"    onClick={this.UpdateEnvironment}>
                                            Update
                                        </Button>
                                    </Col>

                                </Row>
                                <Form.Group controlId="ImplName" >
                                    <Form.Label>Environment Name</Form.Label>
                                    <Form.Control 
                                       
                                        type="text"
                                        rows={1}   
                                       value={strEnvName}
                                        onChange={this.onChangeEnvName } 
                                    />
                                </Form.Group> 
                                <Form.Group controlId="ImplDescription" >
                                    <Form.Label>Environment Description</Form.Label>
                                    <Form.Control 
                                        type="text"                         
                                        as="textarea" 
                                        rows={2}   
                                        value={strEnvDescription } 
                                        onChange={this.onChangeEnvDescription } />
                                </Form.Group>
                                <Form.Group controlId="ImplModelNote" >
                                    <Form.Label>Environment Model Note</Form.Label>
                                    <Form.Control 
                                        type="text"                         
                                        as="textarea" 
                                        rows={2}
                                        value={strEnvModelNote } 
                                        onChange={this.onChangeEnvModelNote } />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col> 
                </span>
                <div id="messagePlaceTris" class="alert alert-primary" role="alert">
                    implementation messages ..
                  </div>
            </Row>

        )
    }
}

