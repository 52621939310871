
import React, { Component } from 'react'
import { Form} from "react-bootstrap";


const showFeedback = (feedback => {
    if (document.getElementById("messagePlace") ){
      document.getElementById("messagePlace").innerHTML = feedback
    }
})



export default class FrameInputRow extends Component {   // props will be { name desc value rows}
    constructor(props) {
        super(props)
        this.onChangeProto = this.onChangeProto.bind(this);
    }


    componentDidMount() {
        
        this.setState({
            attr: this.props.attr,
        });
    };




    onChangeProto(event) {
        // need to know value
        //onChangeDescription(e) {this.setState({ Description: e.target.value })}   // pass it up the line
        //onChangeDescription(e) {this.setState({ Description: e.target.value })}
       showFeedback(event.target.attributes.id.value)
    let kvp = {guid: event.target.attributes.id.value, proto: event.target.value }
        this.props.handleAttrChange(kvp)   // send up to change the state (dont change local state it will ripple down)

        
    }
    render() {
   
        {/**    i was getting undefined state?? */}

      
  

        return (
            <div style={{ color: 'grey'}}>
                <Form.Group controlId={this.props.attr.ATTRGUID} >
                    <b><Form.Label>{this.props.attr.ATTRNAME}</Form.Label></b>&nbsp; &nbsp; &nbsp; &nbsp; 
                    <i><Form.Label>{this.props.attr.ATTRDESC}</Form.Label></i>
                    <Form.Control 
                    className='text-success'
                        type="text"                         
                        as="textarea" 
                        rows={this.props.attr.ATTRROWS}   
                        value={this.props.attr.ATTRPROTYPE} 
                        onChange={this.onChangeProto}
                         />
                </Form.Group>
            </div>
        )
    }
}