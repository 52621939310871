import React, { Component } from "react";
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Row, Form} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import FrameDisplayRow from './FrameDisplayRow';
import FrameInputRow from './FrameInputRow';
import OutputLinkRow from './OutputLinkRow';



import strBEURL from './backend-url';

//let strBEURL = 'https://clammytest.assertions.ca'   // back end url for prod  BE
// strBEURL = 'http://localhost:3030'
// this will use BE get model /amodel/user-model/  which will expect the cookie to be in place

const showFeedback = (feedback => {
    if (document.getElementById("messagePlace") ){
      document.getElementById("messagePlace").innerHTML = feedback
    }
})

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }
  
  export default withRouter(

 class UseCubicle extends Component {
    constructor(props) {
        super(props)
        // State
        this.state = {
            FullCubicle: '',   // the full cubicle object 
            AssnName: '',
            AuthName: '',
            Specification: '',
            DBID: '',
            InputFrames: [],
            BaseFrame: {attributes: []},
        }

        this.updateAttrProto = this.updateAttrProto.bind(this);
        this.updateAttributeProtos = this.updateAttributeProtos.bind(this);
        this.OutputsList = this.OutputsList.bind(this);
        
    }

    componentDidMount() {
        const dbid = this.props.router.params.dbid;
        this.fetchCubicle(dbid)  // will cause Rerender if state changes
    }


    fetchCubicle(dbid) {
console.log(strBEURL)
        let strParam = "?dbid=" + dbid
        showFeedback('... ' )
        let strAxios = strBEURL + '/cubicle/full-cubicle/' + strParam
        axios.get( strAxios) // this gets the full cubicle object
        .then(res => {
            //showFeedback(JSON.stringify(res.data))  // just to check  base.assnTitle.Name
            let inputCopy = JSON.parse(JSON.stringify(res.data))
            this.setState({
                FullCubicle: inputCopy,
                AssnName: inputCopy.base.assnTitle.SpecID + '  - ' + inputCopy.base.assnTitle.Name,
                AuthName: inputCopy.base.assnTitle.AuthName,
                InputFrames: inputCopy.inputs,
                Specification: inputCopy.base.assnTitle.Specification,
                DBID: inputCopy.base.assnTitle.AutoID,
                BaseFrame: inputCopy.base.assnTypes[0],
            });
        })
        .catch((error) => {
            showFeedback('Error fetching Cubicle: ' + error)
        })

    }



    updateAttrProto(pair)  {  // this function is passed down to the row to allow it to update the cubicle ( which then can emit)
        
        this.fetchCubicle()
        
      
        // for now lets just see this register in feedback
        //showFeedback('Got summt from summer, innit: ' + JSON.stringify(pair))
        // if this works then it has to cycle the attributes to change the right one
            // try to change the object  rather thatn recreate  - no bad practice to mutate
        
            let newAttributes = []
            let newAttr
            this.state.BaseFrame.attributes.forEach( attr =>{
                newAttr=  JSON.parse(JSON.stringify(attr))
                if (newAttr.ATTRGUID === pair.guid) {
                   
                    newAttr.ATTRPROTYPE = pair.proto  // still mutating
                }   // this SHOULD change the state all the way down - then to update protos just send the attributes
                newAttributes.push(newAttr)// now add this to a new attributes array
            })
            // this is so ugly
            let typeName = this.state.BaseFrame.typeName
            let strTypeGUID = this.state.BaseFrame.strTypeGUID
           
            this.setState({
                BaseFrame: {
                    typeName: typeName,
                    strTypeGUID: strTypeGUID,
                    attributes: newAttributes,
                }

              });

             
    }

    updateAttributeProtos()  {   // Used to remember the frames values  // this will cycle the attributes push update promises and then do promise all -- then report
      // invoked by EMIT
        let arrPromUpdates = []
        let strParam
        let obUpdate
        let fixedProto
        this.state.BaseFrame.attributes.forEach( attr => {

            fixedProto = attr.ATTRPROTYPE.replaceAll(/\\\\/g, "\\")
            //fixedProto = fixedProto.replace('\\\\','\\');  // sort of worked

            obUpdate = {"strAttributeGUID": attr.ATTRGUID, "PrototypeValue": fixedProto}  // this needs to be conditioned
            //strParam = '?PrototypeValue='+ attr.ATTRPROTYPE+'&strAttributeGUID=' + attr.ATTRGUID
            arrPromUpdates.push(     // the endpoint takes the vaoues to be updated so only ptoto is needed
                //axios.get( strBEURL + '/attribute/update-attribute' + strParam)
                axios.post( strBEURL + '/attribute/post-update-attribute' , JSON.parse(JSON.stringify(obUpdate)))
            )
        })
        Promise.all(arrPromUpdates)
        .then((values) => {
            let feed = ""
            values.forEach( attrResult =>{
                feed += attrResult.data.message + '</br>'
            })
            showFeedback(feed)
            //console.log(values);
        })
        .catch(error => {
               console.log(`::Error::<br> ${error}`)
               showFeedback(JSON.stringify(error))
        })
    }


    FrameRows() {
        return this.state.InputFrames.map((frame, i) => {
           
            if (frame.assnTypes.length > 0) {
                return <FrameDisplayRow dbid={frame.assnTitle.AutoID} authName={frame.assnTitle.AuthName} title={frame.assnTitle.SpecID + ' -- ' + frame.assnTitle.Name} attrs={frame.assnTypes[0].attributes} key={i} />;  //This does each of the inputs  // there will be only one type per frame for now   -- thi FrameDisplayRow will be passed an arg assnTypes (which will be array of assertoins accessed by attributes an array)
            }     
         });
      }


      OutputsList(outputs) {   // This creates and renders an output link row for each outputs 

        //return <OutputLinkRow AutoID={'wtf'}  />;
       //return outputs.map((obj) => { 
        //     return <OutputLinkRow AutoID={obj.AutoID}  />;
       //    });
       let arrOutputs = [{AutoID: 'hey'},{AutoID:'huh'}]  // debug errors
      if (outputs !== undefined) {
       
        arrOutputs = outputs
    }
           return arrOutputs.map((title) => { 
            return <OutputLinkRow title={title}  />;
          });
        }

render() {
    //var inputConnections = this.state.InputConnections
    let arrAttrs = []   // the array of attributes for the frame to be shown in <FrameInputRow>
    let arrOutputs = []   // 
    // before rendering detect if there is null data from full cubicle so that there is no frame or no output 

    //if (!this.state && this.state.hasOwnProperty('BaseFrame') && this.state.BaseFrame.hasOwnProperty('attributes') && this.state.BaseFrame.attributes !== undefined )
       
        if (Array.isArray(this.state.BaseFrame.attributes))
        {arrAttrs = this.state.BaseFrame.attributes
        }
    //if (this.state.FullCubicle.outputs !== undefined )
    if (Array.isArray(this.state.FullCubicle.outputs))

    //if (!this.state && this.state.hasOwnProperty('FullCubicle') && this.state.FullCubicle.hasOwnProperty('outputs') && this.state.FullCubicle.outputs !== undefined )

        {arrOutputs = this.state.FullCubicle.outputs  // dirty means that the object returned needs cleanup
        }

   // let cleanOutputs = JSON.parse(JSON.stringify(dirtyOutputs))
   
    return (
        <div className="form-wrapper">
 
        <Row>
            <Col className="mx-2"> 
            <p>Simulated Screen for: <font color="green"> <b>{this.state.AuthName}</b></font> </p>
                <p><font size="5">{this.state.AssnName  } </font>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <Link
                    className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
                    to={'/edit-assertion/' + this.state.DBID}
                >
                    Edit
                </Link></p> 
                <p><b>Input</b> information from the following assertions:</p>
                {this.FrameRows()}
            </Col>
            <Col className="mx-2">
            <p align="right">
              <Link
                className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
                to={'/explain/KN45'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
                >
                Explain&#62;
              </Link>
              </p>
                <p></p>
                <Row> <span class="square border border-4   "><Col>
                    <p><b>Guidance for determining this Assertion:  </b></p> 
                    <p>{this.state.Specification} </p> 
                </Col></span></Row>
                <p></p>
                <Row> <span class="square border border-3   rl_frameInput"><Col>
                    {arrAttrs.map((attr,i) => {
                        return <FrameInputRow  key={i} handleAttrChange={this.updateAttrProto} attr={attr} />;  //This does each of the inputs  // there will be only one type per frame for now   -- thi FrameDisplayRow will be passed an arg assnTypes (which will be array of assertoins accessed by attributes an array)
                    })}
                    <p></p>
                </Col></span></Row>
                <p></p>
                <Button  onClick={this.updateAttributeProtos} size="sm" variant="primary" >
                    Emit Assertion
                </Button>
                <p>This frame sent to: </p>
                {this.OutputsList(arrOutputs)}
                <div id="messagePlace" class="alert alert-primary" role="alert">
                    ...
                </div>
            </Col>
        </Row>
    
        </div>  
    )


}
}
  )