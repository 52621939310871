import React, { Component } from 'react'
import {  Form } from "react-bootstrap";

import Button from 'react-bootstrap/Button'


///  changes for authority onluy made to here..


export default class IEAOptionsRow extends Component {
  constructor(props) {
    super(props)
  }

  render() {
        {/**    If we can return this repeat then part way there */}
    return <option value={this.props.obj.TriDBID }>{this.props.obj.ImplName  + "  -uses -  " +   this.props.obj.EnvName}</option>

  }
}
