import React, { Component } from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import AuthorityTableRow from './AuthorityTableRow';
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';

import strBEURL from './backend-url';

//let strBEURL = 'https://clammytest.assertions.ca'   // back end url for prod  BE
// strBEURL = 'http://localhost:3030'


export default class AuthorityList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      authorities: []
    };
    
    this.addAuthority = this.addAuthority.bind(this)

   

  }



  addAuthority() {  
    let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
    axios
      .get(
         strBEURL + '/model/add-authority/' + strParams ,
      )
      .then((res) => {     // res.data.message and res.data.payload are avail
        if (res.data.message === 'OK') {
            if (document.getElementById("messagePlace") ){
            document.getElementById("messagePlace").innerHTML =  ' ADDED: OK' 
          }
        } else {
          if (document.getElementById("messagePlace") ){
            document.getElementById("messagePlace").innerHTML =  ' NOT ADDED: '  + res.data.message
          }
        }

      })
      .catch((error) => {
        if (document.getElementById("messagePlace") ){
          document.getElementById("messagePlace").innerHTML =  ' ERROR: ' + error
        }
      })
      this.forceUpdate()
  }

  componentDidMount() {
    this.getAuthorities()
  }
  componentDidUpdate() {
    this.getAuthorities()
  }

  getAuthorities(){
    //  get the strTypeGUID for this assertion ( needs new endpoint)  - or full assertion should get it too
    let  strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
    axios.get( strBEURL + '/model/all-authorities/' + strParams)
    .then(res => {
      if (res.data.message === 'OK') {
        this.setState({
          authorities: res.data.payload
        });
      } 

    })
    .catch((error) => {
      console.log(error);
    })
  }

  DataTable() {
    return this.state.authorities.map((res, i) => {
      return <AuthorityTableRow obj={res} key={i} />;
    });
  }

  render() {
    return (
      <div className="table-wrapper">
        <p align="right">
          <Link
            className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
            to={'/explain/' + 'KN5'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
            >
            Explain&#62;
          </Link>
        </p>
        <h3>Authorities</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th width='6%'>Authority ID</th>
              <th width='25%'>Name </th>
              <th>Description</th>
              <th width='5%'></th>
              <th width='5%'></th>
            </tr>
          </thead>
          <tbody>
            {this.DataTable()}
          </tbody>
        </Table>
        <Button onClick={this.addAuthority} size="sm" variant="danger">
          Add Authority
        </Button>
        <div id="messagePlace" class="alert alert-primary" role="alert">
          feedback ...
        </div>
      </div>
    );
  }
}