import React, { Component } from "react";
import axios from 'axios';
//import Table from 'react-bootstrap/Table';
import AssertionTableRow from './AssertionTableRow';
import { Col, Row, Form, Button, Table, ListGroup} from "react-bootstrap";
//import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';

import strBEURL from './backend-url';

//let strBEURL = 'https://clammytest.assertions.ca'   // back end url for prod  BE
//strBEURL = 'http://localhost:3030'
//    ' +  strBEURL + '
const showFeedback = ((feedback,place) => {
  if (document.getElementById(place) ){
    document.getElementById(place).innerHTML = feedback
  }
})

export default class AssertionList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      assertions: [],
      status: 'OK'
    };

    this.addAssertion = this.addAssertion.bind(this)
    this.HandleAssertionDelete = this.HandleAssertionDelete.bind(this)
    this.getAssnList = this.getAssnList.bind(this)

  


    //this.fnForceUpdate = this.fnForceUpdate.bind(this)  // this function passed to a row to see if i will refresh
//const fnForceUpdate = ( () => { this.forceUpdate()}) 
  }


  componentDidMount() {
    this.getAssnList()
  }
  componentDidUpdate() {
    // this.forceUpdate()  //this does not work!
  }


getAssnList(){

    let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
    //console.log(strParams)
    let url = strBEURL + '/assertions/'  + strParams
    axios.get( url)
      .then(res => {
        if (res.data.message) {
          showFeedback(  " Not Found: " + res.data ,"messagePlace")

        }

        this.setState({
          assertions: res.data
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          status: JSON.stringify(error)
        });

      })
  }


  HandleAssertionDelete(){
    this.getAssnList()
  }



addAssertion() {

  axios
    .post(
       strBEURL + '/assertions/create-assertion/?token=' + localStorage.getItem('USER_MODEL_TOKEN')       ,
    )
    .then((res) => {
      showFeedback(   ' OK: ' + res.data ,"messagePlace")
    })
    .catch((error) => {
      console.log(error)
      showFeedback(  error ,"messagePlace")

    })
  //this.forceUpdate()  // does not seem to work (like authorities)
  this.getAssnList()   // this does work
}

  DataTable() {
    return this.state.assertions.map((res, i) => {
      return <AssertionTableRow obj={res} key={i} HandleAssertionDelete={this.HandleAssertionDelete}/>;  //updateAction={fnForceUpdate}
    });
  }
  render() {
   
    return (
    <div>
      <p align="right">
      <Link
        className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
        to={'/explain/' + 'KN3'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
        >
        Explain&#62;
      </Link>
      </p>
      <Row>
        <Col><p><font size="5" > <b><i>Assertionizer Model</i></b>  </font></p></Col>
        <Col><p align="right">      
          <Button onClick={this.addAssertion} size="sm" variant="danger">
            Add New Assertion
          </Button></p>
        </Col>
      </Row>
  
      <div className="table-wrapper">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>SpecID </th>
              <th>Assertion Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>

            {this.DataTable()}
          </tbody>
        </Table>
      </div>



      <div id="messagePlace" class="alert alert-primary" role="alert">
          Assertions ..
        </div>

    </div> 

    );

  }
}