import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Button from 'react-bootstrap/Button'

import strBEURL from './backend-url';

//let strBEURL = 'https://clammytest.assertions.ca'   // back end url for prod  BE
// strBEURL = 'http://localhost:3030'
//    ' +  strBEURL + '

const showFeedback = ((feedback,place) => {
  if (document.getElementById(place) ){
    document.getElementById(place).innerHTML = feedback
  }
})



export default class AssertionTableRow extends Component {
  constructor(props) {
    super(props)
// set state for frame button
    this.deleteAssertion = this.deleteAssertion.bind(this)
  }
  
  deleteAssertion() {
    showFeedback(" Deleting with DBID: "  + this.props.obj.dbid,"messagePlace"   )
// The strAssnGUID is used to delete attributes and connections 
    axios
      .get(
        strBEURL + '/assertions/delete-assertion/?id=' + this.props.obj.dbid   +  '&strAssnGUID='   +   this.props.obj.strGUID   ,
      )
      .then((res) => {
        this.forceUpdate(); 
        showFeedback(' OK: ' + res.data.message ,"messagePlace"   )
      })
      .catch((error) => {
        showFeedback( ' ERROR: ' + error ,"messagePlace"   )
      })

      this.props.HandleAssertionDelete()  // call the parents fetch to refill Assertion List

 
  }

  render() {
    let typeArgs = JSON.stringify({
        strAssnGUID: this.props.obj.strGUID,
        specID: this.props.obj.SpecID,
        Name: this.props.obj.Name,
        AuthName: this.props.obj.AuthName,  // Auth Name not available here - fix
        ModelID: this.props.obj.ModelID,  // ensures the modelID is passed down in case a new type has to be created
      })

    //let idProps = { dbid:  this.props.obj.dbid , AssnStrGUID: this.props.obj.strGUID }
    //  console.log(idProps)
    return (
     
      <tr>
       <td>{this.props.obj.SpecID }</td>
        <td><font color="navy"><b>{this.props.obj.Name}</b></font></td>
        <td>{this.props.obj.Desc}</td>
        <td>
          <Link
            className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
            //to={'/edit-assertion/' + this.props.obj.dbid   }
            to={'/edit-assertion/' + this.props.obj.dbid}
            //state= {{ dbid: this.props.obj.id }}                //should pass dbid
          >
            Edit 
          </Link>
          </td><td>
     
          <Link
            className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
            to={`/edit-full-type/`+ typeArgs}
            state={{ parTypeArgs: typeArgs }}
          >
            Frame
          </Link>
          <Link
            className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
            to={'/use-cubicle/' + this.props.obj.dbid}
          >
            Simulate
          </Link>
          </td><td>
          <Button onClick={this.deleteAssertion} size="sm" variant="danger">
            Delete
          </Button>
        </td>
      </tr>
    )
  }
}
