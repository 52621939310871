import React, { Component } from "react";
import FullTypeRow from './FullTypeRow';
import axios from 'axios';
import { Link, useLocation, useNavigate,  useParams } from 'react-router-dom';
import { Col, Row, Form} from "react-bootstrap";


//This  endpoint  was written initially to handle compex data typestrBEURL = 'localhost:3030's and then has been backed off - 
// thus there is unused code

import strBEURL from './backend-url';

const showFeedback = (feedback => {
  if (document.getElementById("messagePlaceType") ){
    document.getElementById("messagePlaceType").innerHTML = feedback
  }
})


// this tack-on is wrapped so that you can navigate back to the assertion that calls this frame
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}


export default withRouter(
class EditFulltype extends Component {
//  parameters are:  typeArgs: {  strAssnGUID: <The assertion to show> Name: < name of the type> AuthName:  < the name for the Authority because it is known and simplifies>}
// it uses ep type-for-assertion to ensure that this has a type and attribute  -- so far this seems to work.
// but when it gets typelist it can find subtypes they have not been structured right to find their way into full attributes ( however cubicle does find them ) 

  constructor(props) {
      super(props)
      this.setState({
        FullHierarchy: [],
        strAssnGUID: 'sag',
        assnname:'an',
        authname: 'auth',
        list: [],
        ModelID: ''
     
      });
      this.handleChangeAttrList = this.handleChangeAttrList.bind(this)
  }

  componentDidMount() {
    this.getAssertionType()
  }
  componentDidUpdate() {
    // bad news   this.getAssertionType()   causes loop
  }
  getAssertionType() {

      let typeArgs = JSON.parse(this.props.router.params.typeArgs)   // this is really where we need to seee this appearing
      let strAssnGUID = typeArgs.strAssnGUID
      let assnname = typeArgs.Name
      let AuthName = typeArgs.AuthName
      let ModelID = typeArgs.ModelID
      this.setState({
        FullHierarchy: [],    // the object model intended to include subtyupes - now only one type
        strAssnGUID: strAssnGUID,
        assnname: assnname,
        AuthName:  AuthName,
        ModelID: ModelID
      });

      let url = strBEURL + '/type/type-for-assertion/?strAssnGUID=' + strAssnGUID + '&NewTypeName=' + assnname + '&ModelID=' + ModelID
      axios.get(url)  // this should ensure type is there
      .then(  nada => { 
         //url = 'https://' +  'clammycubicle.assertions.ca' + '/type/type-list/?strAssnGUID=' + strAssnGUID + '&ModelID=' + typeArgs.ModelID
         url = strBEURL + '/type/type-list/?strAssnGUID=' + strAssnGUID + '&ModelID=' + typeArgs.ModelID
        return axios.get(url)})
      .then((response) => {
        if (response.length == 0) {
          showFeedback(' No types found for this assertion ' )
        }
        this.setState({
          FullHierarchy: response.data,  // web says values are in content??
        });
      })
      .catch((error) => { // error is handled in catch block
        if (error.response) { // status code out of the range of 2xx
          showFeedback(' API error: ' + error.response.data)
        } else if (error.request) { // The request was made but no response was received
          showFeedback(' API did not respond: ' + error.response.data)
        } else {// Error on setting up the request
          showFeedback(' Request setup Error: ' + error.message)
        }
      });

  }

  handleChangeAttrList() {  //  this fn to be sent to chold to get it to invoke refresh the attr list  // this does not work so comment out
    this.getAssertionType()    // does this need bind??
  }
   

  render() {
      //var inputConnections = this.state.InputConnections
    console.debug('rendering in edit type');
    let tlist = []
    let assnName = '-'
    let authName = '-'
    if (this.state == null ) { 
      tlist = []
      assnName = '-'
    } else {
      tlist = this.state.FullHierarchy
      assnName = this.state.assnname
      authName = this.state.AuthName
    }
    return (
      <div >
        <Row>
          <Col md={7}>      
            {tlist.length} type(s) found for :  &nbsp;  
            <font size="4" ><b>{assnName}</b></font> <font size="2" color="green" >&nbsp; &nbsp; (by {authName})</font></Col>
          <Col md={{ span: 6, offset: 6 }}>  
            <p align="right">
              <Link
                className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
                to={'/explain/' + 'KN255'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
              >
                Explain&#62;
              </Link>
            </p> 
          </Col>
        </Row>

        {tlist.map( (eachfullType,i) => {
          return <FullTypeRow handleChangeAttrList = {this.handleChangeAttrList} thisFullType={eachfullType} key={i} />;   // Handler not needed
        })}
        <div id="messagePlaceType" class="alert alert-primary" role="alert">
          - new assertions may take 2 minutes to create default attribute (fixed) ---
        </div>
      </div>
    )
  }
}  // end EditFullType Component
)