import React, { Component } from 'react'
import { ListGroup} from "react-bootstrap";


///  changes for authority onluy made to here..


export default class IEAListGroupRow extends Component {
  constructor(props) {
    super(props)
  }


  render() {

    let straw  = "<font color='green' > " + [this.props.obj.ImplName]  + " </font> &LT; IN &GT; " + [this.props.obj.EnvName]
    straw  = this.props.obj.ImplName  + " implemented inn  -   " + this.props.obj.EnvName
    //straw= <span className="rl-attr-proto">huh</span>
       return <option value={this.props.obj.TriDBID}><span class="text-danger"></span>{straw} </option>
  }


}

  
   