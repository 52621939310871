import React, { Component }  from "react";
import { Col, Row} from "react-bootstrap";
import Canvas from './canvas.component';
import Button from 'react-bootstrap/Button'
import { Form} from "react-bootstrap";
import axios from 'axios';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
//import { Canvg } from 'canvg';  // used to get connectors svg into image -- I hope
//import {Canvg} from 'canvg';
import Select from "react-select";   // used for multiselect mambers
import DiagramOptionRow from './DiagramOptionRow.js'

import strBEURL from './backend-url';

//let strBEURL = 'https://clammytest.assertions.ca'   // back end url for prod  BE
//strBEURL = 'http://localhost:3030'
// a utility fn
const showFeedback = ((feedback,place) => {
    if (document.getElementById(place) ){
      document.getElementById(place).innerHTML = feedback
    }
  })
// CODING NOTE   !!!
// the data structures here, although they might  work, are a mess   id is sometimed dbid and somtiems guid .  Great opportunity for clean up 240225
 
export default class Diagrammer extends Component {

    constructor(props) {
        super(props)

        // ------- Binding -------------------
        this.onChangeDiagramName = this.onChangeDiagramName.bind(this)
        this.onChangeDiagramDescription = this.onChangeDiagramDescription.bind(this)
        this.onChangeDiagramModelNote = this.onChangeDiagramModelNote.bind(this)
        this.onChangeDiagram = this.onChangeDiagram.bind(this)
        this.deletePerspective = this.deletePerspective.bind(this)
        this.onChangeDiagram = this.onChangeDiagram.bind(this); // select from diagrams
        this.hdlrSelectAssn = this.hdlrSelectAssn.bind(this)   // rclick
        this.hndlrMemberChange = this.hndlrMemberChange.bind(this)  // selected member
        this.updateDiagram = this.updateDiagram.bind(this)  // update
        this.updatePerspective = this.updatePerspective.bind(this)  // update
        this.addNewPerspective = this.addNewPerspective.bind(this)

        this.onChangeExtraElements = this.onChangeExtraElements.bind(this)
        this.onChangeseq = this.onChangeseq.bind(this)
        this.onChangehyperSeq = this.onChangehyperSeq.bind(this)
        this.viewContributionHTML = this.viewContributionHTML.bind(this)


      

     
    

    
    // Initial State

    let cpd = localStorage.getItem('CURRENT_PERSPECTIVE_GUID')
    
        this.state = {        // what a god awful mess this is..
            FullDiagrams: [],  //                                  MAY NOT BE USED
            ListDiagrams: [],  // just the perspective object for the current one   // Used to populate the select perspective control
            selectedDBID: 'none',   // set by a right click on canvas and used to link out to the assertion selected   // used to show the selected dob
            selectedAssnGUID:  '',
            selectedAssn: '-right click to select',   // set by handler sent to canvas
            CurrentDiagramObjects: null,
            mOptionsSelected: null,    // this becomes the list of intended - as shown in the select options
           // has this format { dbid: dob.dbid,   strAssnGUID: dob.strVAssnGUID, value: dob.dbid, label:  dob.SpecID + ' - ' + dob.Name, name: dob.Name, specID: dob.SpecID }
           // below are populated on fetch
            FullPerspectives: [],   // pulled from Full Perspective Array with each view details
            ModelConnections: [], // pulled from Full Perspective

            GotDiagram: {},       // the object for the current diagram    // This is the details of the current diagram  -- passed with all connections to the canvas
            assnOptions: [],  // populated from assertions - a pruned set of attributes for all assertions
           // its format is {id: assn.id, strVAssnGUID: strVAssnGUID, GUID: assn.strGUID, value: assn.id, label: assn.SpecID + ' - ' + assn.Name}
            currentPerspectiveGUID: cpd      // maintained in cookie and updated on selection   //  Same as selectedDgm.strGUID)
        }
    }
 
 // ===48=============== COMPONENT MOUNT ====================================================================

    componentDidMount() {
        this.fetchFullPespectives()
    }

    fetchFullPespectives(){  

        let listDiagrams
        let currentDOBS
        let selectedOptions
        let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
        axios.get(   strBEURL +   '/diagram/full-perspectives/' + strParams )  // this gets pair: all the diagrams and the connections
        .then(res => {  
            if (!Array.isArray(res.data)){
            this.showfeedback('Error in getting full perspective from api.','messagePlace')
            }
            this.setState({
                FullPerspectives: res.data[0],
                ModelConnections: res.data[1]
            }) // each perspective and its diagramObjects

            if (res.data[0].length > 0) {   
                this.setState({
                    //currentPerspectiveGUID: res.data[0][0].strGUID,   // set the first in the list
                })
                listDiagrams = res.data[0].map(per =>{    //used to show the pick list of perspectives  - the key is the perspectiveGUID  ie the viewassertionGUID
                    return {strGUID: per.strGUID,  Name: per.Name, elemCount: per.diagramobjects.length}
                })
                //selectedOptions = null
// the currentperspectiveGUID exists from the construct
            // find the index into res.data[0] where the strGUID is a hit   KLUDGIE
                let currentIndex = 0
                let thisIndex = 0
                res.data[0].forEach(persp => {    //set the dobs from the first perspective for select members
                        if (persp.strGUID === this.state.currentPerspectiveGUID) {currentIndex = thisIndex}
                        thisIndex += 1
                })
                //selectedOptions = res.data[0][currentIndex].diagramobjects.map(dob => {    //set the dobs from the first perspective for select members
                currentDOBS = res.data[0][currentIndex].diagramobjects.map(dob => {    //set the dobs from the first perspective for select members
                 //   return { id: dob.dbid,  strVAssnGUID: dob.strVAssnGUID, strAssnGUID: dob.GUID, value: dob.dbid, label:  dob.Name}   // Name mus have be concat in tht db if spec id is to show
                    return { dbid: dob.dbid,   strAssnGUID: dob.strAssnGUID,   strVAssnGUID: dob.strVAssnGUID, value: dob.dbid, label:  dob.SpecID + ' - ' + dob.Name, name: dob.Name, specID: dob.SpecID }   // Name mus have be concat in tht db if spec id is to show
                 //  xxxxx   { id: dob.dbid, strVAssnGUID: dob.strVAssnGUID,  GUID: dob.strAssnGUID, value: dob.dbid, label:  dob.Name} 
                })
                this.setState({
                    GotDiagram: res.data[0][currentIndex],   // GotDiagram is the state of the current perspective being shown - changes to name and desc change state and update diagram updates positions so it is ready to be sent  for an update to db
                    ListDiagrams: listDiagrams,
                    mOptionsSelected: currentDOBS,   // this is used to show the text on th elements on the diagram and it contains the intended dobs 
                    CurrentDiagramObjects: currentDOBS,
                })
            }
            
            //let url = 'https://clammytest.assertions.ca/assertions/' + strParams
            let url = strBEURL +  '/assertions/' + strParams
            return axios.get(url)     // get the assertions list to build the state assnOptions  id value label
        })
        .then(listAssertions => {  
            let listAssnOptions =  []
            listAssertions.data.forEach( assn => {
                listAssnOptions.push({dbid: assn.dbid,  strAssnGUID: assn.strGUID, value: assn.dbid, label: assn.SpecID + ' - ' + assn.Name, name: assn.Name , specID: assn.SpecID })
            })
            this.setState({  assnOptions: listAssnOptions   })    // assnOptions is all assertions that might be included in this view ( ie all assns)
            })   
            .catch( error => {   
            showFeedback('Get Diagrams error= ' + JSON.stringify(error),'messagePlace')
        })
};

 // =====103============= SUB COMPONENTS ====================================================================

    OptionList(diagramList) {
        //console.log(diagramList)
        if(diagramList){
            return diagramList.map((obj, i) => {         // i is the index in the array
              return <DiagramOptionRow obj={obj} key={i} />;
            });
        }
    }


 // =====115============= ON CHANGE FUNCTIONS ====================================================================

    onChangeDiagramName(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.Name = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }

    onChangeDiagramDescription(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.Description = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }

    onChangeDiagramModelNote(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.ModelNote = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }

    onChangeseq(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.seq = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }

    onChangeExtraElements(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.ExtraElements = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }
    onChangehyperSeq(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.hyperSeq = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }




    onChangeDiagram(e) {

        let selectedDgm = this.getDiagramForGUID(e.target.value)

        let currentDOBS2 = selectedDgm.diagramobjects.map(dob => {
            return { dbid: dob.dbid,   strAssnGUID: dob.strAssnGUID,   strVAssnGUID: dob.strVAssnGUID, value: dob.dbid, label:  dob.SpecID + ' - ' + dob.Name, name: dob.Name, specID: dob.SpecID }   // Name mus have be concat in tht db if spec id is to show
         
           //return { dbid: dob.dbid,   strAssnGUID: dob.strVAssnGUID, value: dob.dbid, label:  dob.SpecID + ' - ' + dob.Name, name: dob.Name, specID: dob.SpecID }

           // return { id: dob.dbid, strVAssnGUID: dob.strVAssnGUID,  GUID: dob.strAssnGUID, value: dob.dbid, label:  dob.Name}   // Name mus have be concat in tht db if spec id is to show
        })
        this.setState({ 
            GotDiagram: selectedDgm,
            mOptionsSelected: JSON.parse(JSON.stringify(currentDOBS2)),  // on a change diagram this is the same as current  -- deep copy
            CurrentDiagramObjects: currentDOBS2,
            currentPerspectiveGUID: selectedDgm.strGUID
        })   // this should update the canvas?- yes because state change
        window.localStorage.setItem('CURRENT_PERSPECTIVE_GUID', selectedDgm.strGUID); 
        document.getElementById("messagePlace").innerHTML = " Rendering:  " + this.state.GotDiagram.Name  ;
    }

     // =====144============= CRUD FUNCTIONS ===========================================MemberUpdate , deletePerspective< =========================

 // ----------------MEMBER UPDATE --------------------------   

    MemberUpdate(blnCompeteUpdate,selected) {   // selected does not have id just dbid  // it is mOptionsSelected which is changed on change
        // Calculates the delta and if blnCompeteUpdate then actually updates with be call
        // This returns a message for the showPlace of actual change or pending change - calculated each time
        // for each assertion 
            // if in dob and not selected remove  - mark action and act if bln
            // if not in dob and in selected then add at 0,0
            
        if (!Array.isArray(this.state.GotDiagram.diagramobjects)){return 'no diagram set up'}
        let dobGUIDS = this.state.GotDiagram.diagramobjects.map(dob => {return dob.strVAssnGUID})   // array of VassnGUIDS currently on diagram  // some confusion on VAssnGuid it is an Assertion Guid value
      
          //  let dobAssnGUIDS = this.state.GotDiagram.diagramobjects.map(dob => {return dob.strAssnGUID})  // this fails to do anything

    let MYdobAssnGUIDS = []       // just an array of strings so include can be used  -- the asn guids of the dobs on the current diagram
        this.state.GotDiagram.diagramobjects.map((MYdob) => {
            MYdobAssnGUIDS.push(MYdob.strAssnGUID) 
        })



        let selectedAssnGUIDs = selected.map(sel => {return sel.strAssnGUID })  // just the assnGUIDs of the current  dobs (could be duplicate if object is on diagram twice)
        let addList = ''
        let removeList = ''
        let arrRemoveAssnGUIDs = []
        let arrViewassertionsToAdd  = []

        //console.log(this.state.assnOptions)
        
        this.state.assnOptions.forEach((assn) => {  // where assn structure is {id: assn.id, strVAssnGUID: strVAssnGUID, GUID: assn.strGUID, value: assn.id, label: assn.SpecID + ' - ' + assn.Name}
            if (MYdobAssnGUIDS.includes(assn.strAssnGUID)) {  // if this assertion is currently  on the diagram  //  this compares assnGUID not vassnGUID
                if (!(selectedAssnGUIDs.includes(assn.strAssnGUID)) ){    // IE if it is not selected but in the dobs the it is to be removed
                    if (blnCompeteUpdate) {
                        arrRemoveAssnGUIDs.push(assn.strAssnGUID)  // add to the list to be removed 
                    }    
                    removeList += ' &nbsp; &nbsp;        ' + assn.label   + ',<br>'
                }
            } else {        //  otherwise it is not currently on the diagram
                if (selectedAssnGUIDs.includes(assn.strAssnGUID)) {  // but if it is supposed to be
                    if (blnCompeteUpdate) {
                        // add if boolean  
                            arrViewassertionsToAdd.push({
                                "strGUID": "AddNewGUIDinBE",    // this is created in BE for each av to add just before bulk insert
                                "dbid": assn.dbid,     //   this is wrong  --  assn should have SpecID, Name, dbid to go into the vassn
                                //"id": assn.dbid,  //hedging bets
                                //"VAssnGUID": this.state.GotDiagram.strVAssnGUID,  // same as strGUID -- not needed -- shouuld be created by the insert View members
                                "strAssnGUID": assn.strAssnGUID,  
                                "strViewGUID": this.state.GotDiagram.strGUID,   
                                "ModelID": this.state.GotDiagram.ModelID,  
                                "SpecID": assn.specID, 
                                "Name": assn.specID +  ' - ' +assn.name, 
                                "XPos": 10, 
                                "YPos": 10, 
                                "Sequence": 0 
                            })
                    } 
                    addList += '&nbsp; &nbsp;      ' + assn.label  + ',<br>'// add to 'add' ' list
                }
            }
        })   // end assertions
console.log(arrViewassertionsToAdd)

        if (blnCompeteUpdate) {
            let strUpdateResult = 'Member Update Result:<br>'
            let deleteRequest = { "strViewGUID": this.state.GotDiagram.strGUID,
                        "arrAssnGUIDs": arrRemoveAssnGUIDs
            }
        // assume resullt is of standard format  { ok: true ,  message: 'deleted 3'}
            axios.post( strBEURL + '/diagram/delete-view-members/' , deleteRequest)
            .then((res) => {
             strUpdateResult +=  res.data.message + '<br>'    // see the message regardless
                //showFeedback(' Removed Members OK: ' + res.data,'messagePlace')
                return axios.post(  strBEURL + '/diagram/insert-view-members/' , arrViewassertionsToAdd)
            })
            .catch((error) => {   // this catches failed delete
                strUpdateResult += '  Failed delete with message: '  + error  + '  Continue to add. ' 
                //showFeedback( ' Remove Members ERROR: ' + error,'messagePlace')  
                return axios.post(  strBEURL + '/diagram/insert-view-members/' , arrViewassertionsToAdd)
            })
            .then((res) => {
                strUpdateResult +=  res.data.message + '<br> '    // expecting an string
                //showFeedback(' Added Members OK: ' + res.data,'messagePlace')
            })
            .catch((error) => {
                strUpdateResult += '  Failed add with message: '  + error  
                //showFeedback( ' Add Members ERROR: ' + error,'messagePlace')  
            })
            .finally(() =>{
            
                this.fetchFullPespectives()    // to update
                showFeedback( strUpdateResult,'messagePlaceMembers') 
            })
        }
        return 'On Update WILL add : <br>'  + addList + ' and WILL remove: <br>' + removeList
    }
      
    


 // -----231-----------UPDATE PERSPECTIVE --------------------------   


 updatePerspective(e){   // just the perspective details
    // update positions// the diagram objects should have a strGUID which should show in newElements
    // Get the new positions into an update object
    let thisEl
    let thisDiagram = this.state.GotDiagram
    let cancon = document.getElementById("canvasContainer") 

    let guid = this.state.GotDiagram.strGUID
    let model = this.state.GotDiagram.ModelID
    let saveQuery =  '?strViewGUID=' + guid + '&ModelID=' + model 
    let fullHTTPRequest =   strBEURL +   '/xprs-image/save-image' + saveQuery     // this is in middleware in the app.js

    // this gets each dob and finds its posn OK and then sets its new posn =--  This is working OK!!

    showFeedback('Updating Perspective ' ,'messagePlace')


    let newElements = thisDiagram.diagramobjects.map(dob =>{   
        thisEl = document.getElementById( 'dgm_' + dob.dbid)          //  this might not work
        // strGUID (of the dob) stays same
        dob.XPos = thisEl.getBoundingClientRect().left - 50   // kludge for origin  - to be fixed
        dob.YPos = thisEl.getBoundingClientRect().top - 10
        return dob
    })  
    // message elenents updated
    let strPosList = 'Positions \n\r'
    newElements.forEach(el => {
        strPosList += el.strGUID + '-- ' + el.Name + '  (' + el.XPos + ',' + el.YPos + ')  <br>'
    })
    showFeedback('UPDATING with elements=' +  strPosList, 'messagePlace')

    //prep axios request
    let dgmToUpdate = { strGUID: this.state.GotDiagram.strGUID,
        Name: this.state.GotDiagram.Name,
        Description: this.state.GotDiagram.Description,
        ModelNote: this.state.GotDiagram.ModelNote,
        elements: newElements
    }
        // Now just use axios to Post the update
    
    axios.post( 
       strBEURL +   '/diagram/update-full-perspective/',
        JSON.stringify(dgmToUpdate),
        {
            headers: {'content-type': 'application/json'},
            crossOrigin: true,
        }
    )  
    .then(res => {
    showFeedback('UPDATE RESULT=' + JSON.stringify(res.data),'messagePlace')
    })
    .catch((error) => {
        showFeedback('error= ' + JSON.stringify(error),'messagePlace')
    })  

    // Save what we got at least -------------------------------------------------
  
}




    updateDiagram(e){    // just the digram image
        // update positions// the diagram objects should have a strGUID which should show in newElements
        // Get the new positions into an update object
        let thisEl
        let thisDiagram = this.state.GotDiagram
        let cancon = document.getElementById("canvasContainer") 

        let guid = this.state.GotDiagram.strGUID
        let model = this.state.GotDiagram.ModelID
        let saveQuery =  '?strViewGUID=' + guid + '&ModelID=' + model 
        let fullHTTPRequest =  strBEURL +   '/xprs-image/save-image' + saveQuery     // this is in middleware in the app.js

        // this gets each dob and finds its posn OK and then sets its new posn =--  This is working OK!!

        showFeedback('Saving Diagram Image to HyperRep' ,'messagePlace')

        html2canvas(cancon)
        .then(function(canvas) {
            canvas.toBlob( (blob) => { 
                axios.post(fullHTTPRequest, blob, {    //  now just write be endpoint for this just to save it
                    headers: {
                    'Content-Type': 'image/png'
                    }
                })
                .then( ans =>{
                    showFeedback('Diagram in Hyperrep is updated:' + ans.data + '\n Now update Perspective to update positions ... ','messagePlace' )
                    //this.updatePerspective(e)  // tossed in to ensure locations are updated too  //   does not work
                    //showFeedback('Diagram ands its positions in Hyperrep is updated:' + ans.data,'messagePlace' )
                    //showFeedback('UPDATE  DIAGRAM IMAGE RESULTT=' + JSON.stringify(ans.data) + '.  fullHTTPRequest: ' + fullHTTPRequest,'messagePlace') // hide so the message on update shows 
                })
                .catch((error) => {
                    showFeedback('UPDATE  DIAGRAM IMAGE RESULT ERROR:=' + error,'messagePlace') 
                    alert(' Save image error : ' + error) 
                })
            })
    
       })
       .catch((error) => {
        showFeedback('UPDATE  DIAGRAM IMAGE CATCH ERROR:=' + error,'messagePlace') 
        alert(' save image error : ' + error) 
    })
        
    }



 // ----------------DELETE PERSPECTIVE --------------------------    
// need to get current diagram and its strViewGUID

    deletePerspective(e) {
        showFeedback('Starting delete for got dgm: ' + this.state.GotDiagram.strGUID,'messagePlace')
       let strDeleteViewGUID = this.state.GotDiagram.strGUID
      
        axios.get(  strBEURL + '/diagram/delete-perspective/?strViewGUID=' + strDeleteViewGUID,)  // this works but errors to 500
        .then((res) => {
            if (res.data.OK) {showFeedback( res.data.message,'messagePlace')}
              this.fetchFullPespectives()
        })
        .catch((error) => {
            showFeedback( 'FAILED in delete with error: ' + error)
        })
    }

     addNewPerspective(e){
        //showFeedback('TO BE WRITTEN - Add New Perspective. BE is done','messagePlace')
        showFeedback('Starting add new perspective ' ,'messagePlace')
        let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
        axios.get(  strBEURL + '/diagram/add-perspective/' + strParams,)
        .then((res) => {
            showFeedback(' ADD OK: ' + res.data,'messagePlace')
               this.fetchFullPespectives() 
        })
        .catch((error) => {
            showFeedback( ' ADD ERROR: ' + error,'messagePlace')  
        })
    }


   // === 249======  HANDLER FUNCTIONS =============hdlrSelectAssn====hndlrMemberChange===========================================
    hdlrSelectAssn = (elSel) => { 
        this.setState({ selectedDBID: elSel.dbid}) 
        this.setState({ selectedAssn: elSel.asnName}) 
        console.log('Setting State selectedDBID: ' + elSel.dbid + '- '+ elSel.asnName)   //   IS THIS CORRECT ?????
    }  // used by child canvas  - passed down

    hndlrMemberChange = (selected) => {

        showFeedback('selected= ' + JSON.stringify(selected),'messagePlaceMembers')
        this.setState({mOptionsSelected: selected})
        showFeedback(this.MemberUpdate(false,selected),'messagePlaceMembers')  // too cute -- returns all somehow
    };
    // ====262============== SUPPORT FUNCTIONS getDiagramForGUID

    getDiagramForGUID (strGUID) {
        let dgm = this.state.FullPerspectives.filter(dgm => {
        return dgm.strGUID === strGUID
        })   // array of 1
        return dgm[0]
    }


    viewContributionHTML(e) {  // send to BE to bulid and link to it  // this will need the perspective GUID and the selected assertion GUID

        let arrSelAssn =  this.state.assnOptions.find((obj) => obj.dbid === this.state.selectedDBID).strAssnGUID  // empty if nothing selected
        if (arrSelAssn.length <1) {
            showFeedback('Assertion not selected.',"messagePlace")
            return
        }
        let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
        strParams += '&perspectiveGUID=' + this.state.currentPerspectiveGUID  
        strParams += '&rootAssnGUID=' + arrSelAssn 
        let url =   strBEURL + '/hyperrep/html-contribution-structure/' + strParams
    
        showFeedback('Creating Contribution Report. About 5 seconds...',"messagePlace")
    
        axios.get(url)
        .then((res) => {
          this.setState({
            hyperURL: res.data
    
          });
          showFeedback('Hyperlink saved to: ' + res.data.MESSAGE,"messagePlace")
    
          const now = new Date(); // this to make url unique each render so FE refreshes
          window.location.assign( res.data.PAYLOAD +  '?' + now.toISOString() )   // now causes time to be added to url so it will refresh
           //now jut go there
        })
        .catch((error) => {
          this.setState({
            hyperURL: error
          });
        })
    
    }




 // ====273============== RENDER ====================================================================

    render() {

        if (this.state){return (
            <div >
                <p align="right">
                    <Link
                        className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
                        to={'/explain/KN11'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
                    >
                    Explain&#62;
                    </Link>
                </p>
             <Row>
              {/**   <Col xs={4}><p style={{ fontSize: 32, padding: 3 , color: "navy", width: "600px"}}>{this.state.GotDiagram.Name }</p></Col>*/}
                <Col xs={10}>
                    <Form.Control 
                                size="lg"    
                                as="select" 
                                custom  
                                onChange={this.onChangeDiagram}
                                style={{ fontSize: 26, padding: 3 , color: "navy", width: "600px"}}
                                value={this.state.currentPerspectiveGUID}
                            >
                                {this.OptionList(this.state.ListDiagrams)}
                            </Form.Control>
                            </Col><Col xs={2}>
                        <Button onClick={this.addNewPerspective}  >
                        New
                        </Button>
                            &nbsp;&nbsp;
                        <Button variant='danger' onClick={this.deletePerspective}  >
                            Delete
                        </Button> 
                    
              
                </Col>
            </Row>
                <Canvas   diagram={this.state.GotDiagram}   connections={this.state.ModelConnections} hdlrSelectAssn = {this.hdlrSelectAssn}  />   
                <Row>
                    <Col>
                    <p>
                        <Link
                            className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
                            to={'/edit-assertion/' + this.state.selectedDBID}
                            >
                            Edit Selected Assertion: ___      {this.state.selectedAssn}
                        </Link>
                    </p>



                    <Form  class="form-inline" onSubmit={this.onSubmit}> 

                    <Button onClick={this.updatePerspective} size="sm" variant="warning">
                        Update Perspective Details
                    </Button> ---
                    <Button onClick={this.updateDiagram} size="sm" variant="warning">
                        Update HyperRep Diagram
                    </Button>
                    <Button onClick={this.viewContributionHTML} size="sm" variant="success">
                        View Contribution HTML for selected assertion
                    </Button>

                        <Form.Group controlId="Diagram-name-choice" >

                        </Form.Group> 
                        <Row>
                        <span class="square border border-4" >
                            <Form.Group controlId="Diagram-name" >
                                <Form.Label >Perspective Name </Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        type="textarea" 
                                        value={this.state.GotDiagram.Name } 
                                        onChange={this.onChangeDiagramName } 
                                    />
                            </Form.Group> 
                            <Form.Group controlId="Diagram-description" >
                                <Form.Label>Perspective Description </Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        value={this.state.GotDiagram.Description } 
                                        onChange={this.onChangeDiagramDescription } 
                                        rows={4}
                                    />
                            </Form.Group> 
                            <Form.Group controlId="model-note" >
                                <Form.Label>Perspective Model Note </Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        value={this.state.GotDiagram.ModelNote } 
                                        onChange={this.onChangeDiagramModelNote } 
                                        rows={4}
                                    />
                            </Form.Group> 
                            <Form.Group controlId="extra-elements" >
                                <Form.Label>Extra Elements </Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        value={this.state.GotDiagram.ExtraElements } 
                                        onChange={this.onChangeExtraElements } 
                                        rows={2}
                                    />
                            </Form.Group>                    
                            <Row>
                                <Col  xs={5}>                            
                                    <Form.Group controlId="model-note" >
                                    <Form.Label>Sequence </Form.Label>
                                        <Form.Control 
                                            as="textarea" 
                                            value={this.state.GotDiagram.seq } 
                                            onChange={this.onChangeseq } 
                                            rows={1}
                                        />
                                    </Form.Group> 
                                </Col>
                                <Col xs={5}>                            
                                    <Form.Group controlId="model-note" >
                                    <Form.Label>HyperRep Sequence </Form.Label>
                                        <Form.Control 
                                            as="textarea" 
                                            value={this.state.GotDiagram.hyperSeq } 
                                            onChange={this.onChangehyperSeq } 
                                            rows={1}
                                        />
                                    </Form.Group> 
                                </Col>
                            </Row>

                         
                    <div id="messagePlace" class="alert alert-primary" role="alert">
                    .. diagram details
                    </div>
                        </span>
                </Row>

                        </Form>
                    
                        </Col>
                        <Col>
                            <p></p>
                            <p>Included Assertions:-</p>
                            <Select
                                isMulti
                                placeholder="none"
                                onChange={this.hndlrMemberChange}
                                value={this.state.mOptionsSelected}
                                options={this.state.assnOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                />
                            <div id="messagePlaceMembers" class="alert alert-primary" role="alert">
                                .. membership details (change expected)
                            </div>
                            <Button onClick={() => { 
                                    showFeedback(this.MemberUpdate(true,this.state.mOptionsSelected),'messagePlaceMembers')    // this startd the actual updated to included dobs
                            }} size="sm" variant="warning">
                                Update members of this Perspective
                            </Button>
                        </Col>
                    </Row>
       
              
            </div>
       );}
       else{return(null)}

    }  // end render
}


